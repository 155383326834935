.contatoContainer {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

}


.containerRowColumn {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}


.contatoImagem {
    position: relative;
    width: 100%;
    height: 40rem;
    object-fit: cover;
}

.contatoInfo1 {
    position: relative;
    width: 15vw;
    height: 100%;
    display: flex;
    flex-direction: column;
    text-align: left;
}

.contatoInfo1 h1 {
    color: white;
}

.contatoInfo1 h2 {
    color: white;
}

.contatoInfo1 span {
    color: white;
    font-size: 1.4rem;
}


.contatoBackButton {
    position: relative;
    width: 100%;
}

.contatoH1 {
    color: #541084 !important;
    font-size: 25px;
    width: 40vw;

}

@media (min-width: 600px) {
    .contatoH1 {

        width: auto;

    }
}



.contatoForm {

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.contatoLabel {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-self: flex-start;
    color: #541084;
    font-size: 1.5rem;
    font-weight: bold;
    margin-top: 0.5rem;
}

.contatoInput {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-self: start;
    font-size: 0.9rem;
    height: 3rem;
    font-weight: bold;
    margin-top: 0.5rem;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    margin-inline: 13em;
}

.contatoTextarea {
    font-family: Rubik;
    display: flex;
    flex-direction: column;
    width: 88%;
    align-self: start;
    font-size: 1rem;
    height: 12rem;
    font-weight: 200;
    margin-top: 0.5rem;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    padding-left: 7px;
}

.contatoSubmit {
    background: #9248FF;
    color: #FFF;

    font-family: Rubik;
    padding: 5px 5px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    cursor: pointer;
    border: none;
    border-radius: 0.5rem;
    align-self: flex-end;
    margin-top: 1vw;

    margin-right: 5vw;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    font-family: 'Rubik', sans-serif;
}

.contatoH2 {
    color: #541084 !important;

    width: auto;
    padding-top: 3rem;
    font-size: 1.2rem;


    line-height: 30px !important;
    align-self: center !important;

}

@media (min-width: 600px) {
    .contatoH2 {
        color: #541084 !important;

        width: auto;
        padding: 1rem;
        font-size: 1.2rem;
        padding: 0 !important;
        padding-inline: 3rem !important;
        line-height: 30px !important;
        align-self: center !important;

    }

    .contatoInput {
        margin-inline: 0;
        padding-left: 1rem;
        border: 1px solid rgba(92, 92, 92, 0.438);
        opacity: 0.8;
        background-color: whitesmoke;
        width: 50%;
        align-self: left;
    }

    .contatoTextarea {
        width: 100%;
        border: 1px solid rgba(92, 92, 92, 0.438);
        opacity: 0.8;
        background-color: whitesmoke;
        width: 50%;
    }

    .contatoSubmit {
        align-self: flex-start;
        font-size: 24px;
        padding: 5px 15px;
    }
}





.divColumn {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-inline: 15rem;
}

.contatoInfoContainer {
    display: flex;
    flex-direction: column;
    margin-left: 28vw;
    gap: 3vw;
}

.posicaoAbsolutaDireita {
    display: flex;
    position: absolute;
    left: 0%;
    width: 100%;
    justify-content: flex-end;
    margin-bottom: 5vw;
}

.caretCircleLeftStyle {
    color: white;
    width: 150px;
    height: 100px;
    margin-right: 2rem;

}

@media (max-width: 1140px) {
    .contatoInfoContainer {
        margin-left: 18vw;
        margin-right: 9vw;

    }



    .contatoSubmit {
        margin-right: 0;
        margin: 15px 2px;
    }

    .caretCircleLeftStyle {
        margin-right: 2rem;
    }
}





@media (max-width: 600px) {
    .posicaoAbsolutaDireita {
        position: relative;
        margin-left: 5vw;
        justify-content: flex-end;
        margin-bottom: 5vw;
    }

    .contatoBackButton {
        position: relative;
        width: 85%;
    }

    .caretCircleLeftStyle {
        color: white;
        width: auto;
        height: 60px;
        position: absolute;
        right: 0;
        bottom: 0;

    }
}

@media (max-width: 600px) {
    .contatoContainer {
        background-color: #FFFFFF;
    }

    .containerRowColumn {
        flex-direction: column;
    }

    .contatoImagem {
        /* Seus estilos aqui */
    }

    .contatoInfo1 {
        /* Seus estilos aqui */
    }

    .contatoInfo1 h1 {
        color: white;
        font-size: 1.5rem;
        padding-inline: 0rem;
    }

    .contatoInfo1 h2 {
        font-size: 1rem;
        font-weight: 400;
        padding-inline: 0rem;
    }

    .contatoInfo1 span {
        color: white;
        font-size: 1.1rem;
        margin-bottom: 1rem;
    }

    .contatoBackButton {
        /* Seus estilos aqui */
    }

    .contatoH1 {
        width: auto;
        padding: 1rem;
        font-size: 1.2rem;
        padding: 0 !important;
        padding-inline: 3rem !important;
        line-height: 30px !important;
    }

    .contatoForm {
        width: 100%;
        padding: 1rem;
    }

    .contatoLabel {
        font-size: 1rem;
        width: 100%;
        margin-inline: 1.5rem;
        font-family: Rubik;
        font-weight: 400;
    }

    .contatoInput {
        font-family: Rubik;
        font-weight: 100;
        padding: 1rem;
        margin: 0rem;
        margin-top: 0.5rem;
        width: 87%;
        border: none;
        background-color: #F8F7FD;
    }

    .contatoTextarea {
        border: none;
        background-color: #F8F7FD;
        padding: 1rem;
    }

    .contatoSubmit {
        padding: 5px 15px;
        margin: 16px 0px;
        align-self: center;
    }

    .divColumn {
        align-items: center;
        justify-content: center;
        width: 100%;
    }

    .contatoInfoContainer {
        display: flex;
        align-self: flex-start;
        flex-direction: column;
        margin-left: 1rem;
        gap: 3vw;
        margin-bottom: 5rem;

    }

    .caretCircleLeftStyle {
        color: white;
        width: auto;
        height: 60px;
        position: absolute;
        right: 0;
        bottom: 0;
        margin-right: 0vw;

    }

    .contatoContainer img {
        position: relative;


        /* display: flex; */
        flex-direction: column;
        align-items: center;
        justify-content: center;
        /* margin-bottom: 1rem; */
    }
}

.logoRowContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    padding: 3rem 3rem;
    background-color: #F8F7FD;
    border-radius: 0px 0px 35px 35px;
    -webkit-border-radius: 0px 0px 35px 35px;
    -moz-border-radius: 0px 0px 35px 35px;
    -ms-border-radius: 0px 0px 35px 35px;
    -o-border-radius: 0px 0px 35px 35px;
}

.logoRowContainer img {
    width: auto;
    height: 3rem;
    margin: 0;
}

.logoRowContainer svg {
    font-size: 3rem;
}

.containerPropaganda {
    width: 84%;
    height: 4rem;
    margin: 2rem;
    background-color: transparent;

}

.containerPropaganda.playing {}

.box {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-block: 2rem;
}

.box .rectangle {
    background-color: transparent;
    height: 200px;

    width: 200px;
}

.footerContainer {
    width: 100%;
    background-color: #f8f7fd;
    border-radius: 35px 35px 0px 0px;
    height: 15rem;
    margin-top: 4rem;
    -webkit-border-radius: 35px 35px 0px 0px;
    -moz-border-radius: 35px 35px 0px 0px;
    -ms-border-radius: 35px 35px 0px 0px;
    -o-border-radius: 35px 35px 0px 0px;
}

.footerDivContato {
    background-color: #f8f7fd;
    border-radius: 35px 35px 0px 0px;
    -webkit-border-radius: 35px 35px 0px 0px;
    -moz-border-radius: 35px 35px 0px 0px;
    -ms-border-radius: 35px 35px 0px 0px;
    -o-border-radius: 35px 35px 0px 0px;
}

.footerDivRowContato {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    padding: 2rem;
}

.footerDivColumnContato svg {
    font-size: 1.1rem;

}

.text-wrapper {
    font-weight: 700;
    font-size: 0.8rem;
}

.span {
    font-family: "Rubik-Medium", Helvetica;
    font-weight: 400;
    font-size: 0.8rem;
}

.footerSocialmediaContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 2rem;
}

.footerSocialmediaContainer img {
    width: auto;
    height: 1.2rem;
    margin: 0;
    padding: 0;
}

@media (min-width: 600px) {
    .logoMenuDivRow.playingAndContactPage { top: 4.5%;}

    .footerDivRowContato {
        padding-inline: 20rem;
        height: 15rem;
    }

    .footerDivColumnContato svg {
        font-size: 4.1rem;

    }

    .text-wrapper {
        font-weight: 700;
        font-size: 1.8rem;
    }

    .span {
        font-family: "Rubik-Medium", Helvetica;
        font-weight: 400;
        font-size: 1.8rem;
    }

    .footerSocialmediaContainer {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        padding-inline: 15rem;
        height: 15rem;
    }

    .footerSocialmediaContainer img {
        width: auto;
        height: 2.8rem;
        margin: 0;
        padding: 0;
    }

    .footerSocialmediaContainer svg {
        width: 55px;
        height: 55px;
    }

    .contactPage {
        background-color: white;
        justify-content: space-between;
        padding-inline: 9rem;
        background-image: none;
    }

    .containerPropaganda {
        width: 84%;
        height: 16rem;
        margin: 2rem;
        background-color: transparent;
        margin-top: 7rem;
    }
}

.footerDivColumnContato {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #541084;

}




.select-container::after {
    content: "\25BC";
    position: absolute;
    top: -7%;
    right: 0;
    padding: 10px;
    pointer-events: none;
    user-select: none;
    color: white;
}

.logoMenuDivRow svg.newsPageIconS {
    color: #541084 !important;
}

@media (max-width: 600px) {
    .logoMenuDivRow svg.newsPageIconS {
        color: white !important;
    }

    .select-container::after {
        content: "\25BC";
        position: absolute;
        top: 5%;
        right: -4%;
        padding: 10px;
        pointer-events: none;
        user-select: none;
        color: white;
    }

    .divColumn {
        display: flex;
        flex-direction: column;
        padding-inline: 0rem;
    }
}

.contatoH2 {

    margin-top: 7rem;
}

.contatoH2.playing {
    margin-top: 10rem;
}

.whiteLineContato {
    background-color: var(--cor-primaria);
    margin-top: 6px;
    /* margin-bottom: 1.56rem; */
    height: 3px;
    width: 6%;
}

.select-container {
    position: relative;
    display: inline-block;
}


.contactPage {
    top: 1%;
    padding-block: 0.3rem !important;

}
.contatoContainer {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.contatoContainer h1 {
    color: var(--cor-terciaria);
    font-size: 2rem;
    padding-block: 2rem;

    letter-spacing: -0.58px;
    font-weight: 200;
    line-height: 50px;

}

.h1Sobre {
    color: var(--cor-terciaria);
    font-size: 2rem;
    padding-block: 2rem;
    letter-spacing: -0.58px;
    font-weight: 200;
    line-height: 27px;
    padding-inline: 2rem;
}

.contatoContainer h2 {
    color: var(--cor-terciaria);
    font-size: 2rem;

    padding-top: 7rem;
    font-weight: 200;
    align-self: flex-start;
}

.sobreNos {
    color: var(--cor-terciaria);
    font-size: 2rem;
    padding-inline: 2rem;
    padding-top: 2rem;
    font-weight: 200;
    align-self: flex-start;
}

.sobreNos1 {
    color: var(--cor-primaria);
    font-size: 2rem;
    padding-inline: 2rem;
    padding-top: 2rem;
    font-weight: 200;
    align-self: flex-start;
}

.lineSobre {
    height: 2px;
    width: 55%;
    margin-block: 10px;
    background-color: red;
    align-self: flex-start;
    margin-left: 2rem;
}

.caretCircleLeftStyle1 {
    color: white;
    width: 150px;
    height: 100px;
    margin-right: 6rem;
    margin-top: 10rem;
}

.posicaoAbsolutaDireita1 {
    display: flex;
    position: absolute;
    left: 0%;
    width: 100%;
    justify-content: flex-end;
    margin-bottom: 5vw;
}

.contatoContainer.primary {
    background-color: var(--cor-primaria);
    height: 100vh;
    overflow: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    /* substitua #corPrimaria pela cor primária do seu tema */
}

.boxMapPropaganda4 {
    background-color: #D9D9D9;
    width: 20rem;
    height: 3rem;
    margin-block: 4vw;
}

@media (max-width: 600px) {
    .caretCircleLeftStyle1 {
        color: white;
        width: 150px;
        height: 100px;
        margin-right: 2rem;
    }

    .contatoContainer.primary {
        background-color: var(--cor-primaria);
        height: 115vh;
        overflow: none;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        /* substitua #corPrimaria pela cor primária do seu tema */
    }

    .contatoContainer {
        background-color: var(--cor-primaria);
        height: 200vw;
        overflow: none;
    }

    .boxMapPropaganda4 {
        background-color: #D9D9D9;
        width: 20rem;
        height: 3rem;
        margin-block: 8vw;
    }

    .contatoContainer h1 {
        color: var(--cor-terciaria);
        font-size: 1rem;

    }



    .caretCircleLeftStyle1 {
        color: white;
        width: auto;
        height: 60px;
        position: absolute;
        right: 0;
        bottom: 0;
        margin-right: 0vw;
    }

    .posicaoAbsolutaDireita1 {
        position: relative;
        margin-right: 3vw;
        justify-content: flex-end;
        margin-bottom: 0vw;
        margin-top: 5rem;
    }
}
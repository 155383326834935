.containerPrincipal {
  /* Estilos para o container principal */
}

.MenuContainerHeader {
  /* Estilos para o container do menu e cabeçalho */
}

.App-headerN {
  /* Estilos para o cabeçalho */
}

.linkLogo {
  /* Estilos para o link do logo */
}

.imagemLogo {
  /* Estilos para a imagem do logo */
}

.divMenu {
  /* Estilos para o div do menu */
}

.menuDiv {
  /* Estilos para o div do menu */
}

.divMenuSpan {
  /* Estilos para o span do div do menu */
}

.socialIcons {
  /* Estilos para os ícones sociais */
}

.iconeInstagram,
.iconeFacebook,
.iconeYoutube,
.iconeTwitter {
  /* Estilos para os ícones das redes sociais */
}

.containerNoticia {
  border-radius: 10px;
  width: 100%;
  padding: 10px;
}

.imagemNoticia {
  width: 25vw;
  height: 16vw;
  border-radius: 10px;
  object-fit: cover;
}

h4 {
  margin: 0;
  padding: 0;
  font-size: 1.8rem;
  color: #541084;
  font-family: "Rubik", sans-serif;

  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

h5 {
  margin: 0;
  padding: 0;

  font-family: "Rubik", sans-serif;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  opacity: 0.8;
}

.botaoContainer {
  display: flex;
  justify-content: center;
  margin: 20px 0;
  width: 100%;
}

.botaoCarregarMais {
  margin: 20px;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
  background-color: #541084;
  /* Cor de fundo roxa */
  border: 2px solid #7d33a6;
  /* Borda roxa mais clara */
  color: #ffffff;
  /* Cor do texto branca */
  font-size: 16px;
  /* Tamanho do texto */
  font-weight: bold;
  /* Texto em negrito */
  transition: background-color 0.3s ease;
  /* Transição suave ao mudar a cor de fundo */
}

.botaoCarregarMais:hover {
  background-color: #7d33a6;
  /* Cor de fundo roxa mais clara ao passar o mouse */
}

.dividerLine {
  display: block;
  /* Mostra o divisor por padrão */
}

.contentBackgroundDrops {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-inline: 2rem;
  background: linear-gradient(0deg, #541084 0%, #ffc007 100%);
}

@media (min-width: 600px) {
  .contentBackgroundDrops {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding-inline: 0rem;
    background: transparent;
    background-color: white;
    overflow: hidden;
  }

  .dividerLine {
    display: none;
    /* Esconde o divisor quando a largura da tela é maior que 600px */
  }
}

.news-grid {
  margin-block: 5rem;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 6rem;
}

@media (max-width: 1748px) {
  .news-grid {
    margin-block: 5rem;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 5rem;
  }
}

@media (max-width: 1682px) {
  .news-grid {
    margin-block: 5rem;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 4rem;
  }
}

.news-card {
  border: 1px solid rgb(204, 204, 204);
  border-radius: 10px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
  z-index: 2;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
}

.news-card::after {
  content: "";
  position: absolute;
  bottom: 4%;
  left: 0;
  width: 100%;
  height: 20%;
  background-color: white;
  border-top: 4px solid #ffc007;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  z-index: 1;
}

.news-card img {
  width: 30rem;
  height: 17rem;
  object-fit: cover;
}

.news-card h2 {
  margin: 0;
  padding: 5px;
  width: 30rem;
  height: 3rem;
  text-align: center;
  color: #541084;
  font-family: "Rubik", sans-serif;
  z-index: 2;
  margin-bottom: 18px !important;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

@media (max-width: 1659px) {
  .news-card img {
    width: 28rem;
    height: 16rem;
  }

  .news-card h2 {
    width: 28rem;
    font-size: 18px;
  }
}

@media (max-width: 1550px) {
  .news-card img {
    width: 25rem;
    height: 16rem;
  }

  .news-card h2 {
    width: 25rem;
    font-size: 16px;
  }
}

@media (max-width: 1430px) {
  .news-card img {
    width: 22rem;
    height: 14rem;
  }

  .news-card h2 {
    width: 22rem;
    font-size: 16px;
    padding: 12px;
  }
}

.news-card p {
  width: auto;
  background-color: #ffc007;
  color: #541084;
  font-size: 14px;
  padding: 2px 10px;
  border-radius: 12px;
  -webkit-border-radius: 12px;
  -moz-border-radius: 12px;
  -ms-border-radius: 12px;
  -o-border-radius: 12px;
  position: absolute;
  bottom: 21%;
  font-family: "Rubik", sans-serif;
  z-index: 2;

  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.propagandaDivDrops {
  width: 65%;
  align-self: center;
  margin: auto;
  margin-block: 1rem;
  margin-bottom: 4rem;
}

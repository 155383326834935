* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
  color: inherit;
}

h1 {
  color: #000;
  /* Ajuste esta cor conforme necessário */
}

body.noScroll {
}

:root {
  /* --cor-primaria: #DC143C;
  --cor-primaria-clara: #E26A6E; */
  --tamanho-base-fonte: 16px;
  --cor-primaria: #541084;
  --cor-primaria-clara: #8d1b94;
  --cor-secundaria: #ffc007;
  --cor-terciaria: white;
  --cor-text: black;
  --cor-backgroundClaro: #ffc300;
  --cor-backgroundEscuro: #ff5733;
}

html {
  font-size: var(--tamanho-base-fonte);
}

body {
  font-size: 1rem;
  /* Corpo do texto */
}

h1 {
  font-size: 2rem;
  /* Títulos principais */
}

h2 {
  font-size: 1.5rem;
  /* Subtítulos */
}

h3 {
  font-size: 1.2rem;
  /* Títulos de seção */
}

p {
  font-size: 1rem;
  /* Parágrafos */
}

a {
  font-size: 1rem;
  /* Links */
}

.mapComponent {
  width: 38vw;
  overflow: visible;
  cursor: pointer;
}

@media (max-width: 600px) {
  .mapComponent {
    width: 88vw;
    margin-right: 2vw;
    margin-top: 2vw;
    /* ou qualquer outro valor que você deseja */
  }
}

.App {
  align-items: center;
  display: flex;
  flex-direction: column;
  padding-bottom: 0vw;
  position: relative;
  width: 100%;
  background-color: #541084;
}

.App {
  position: relative;
}

.social-link {
  width: 3vw;
  height: 3vw;
}

@media (max-width: 600px) {
  .social-link {
    width: 5vw;
    height: 5vw;
  }
}

.MapContainer {
  align-items: center;
  background: linear-gradient(180deg, #541084 0%, #9248ff 100%);

  /* background-color: #FFC007; */
  display: flex;
  flex-direction: column;
  height: auto;
  justify-content: center;
  padding-bottom: 12vw;
  padding-top: 0vw;
  position: relative;
  width: 100%;
}

@media (min-width: 600px) {
  .MapContainer {
    background: none;
  }
}

.dropsColumnContainer span {
  bottom: -14%;
  color: var(--cor-primaria);
  font-family: "Rubik", sans-serif;
  font-size: calc(0.8vw + 0.2vw);
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-inline: 0.5vw;
  position: absolute;
  text-align: center;
  z-index: 5;
}

.dropsRowContainer img {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  height: 13vw;
  object-fit: cover;

  width: 23.2vw;
}

.first-row {
  margin-bottom: 5.5vw;
  width: auto;
}

.dropsCardContainer {
  display: flex;
  flex-direction: row;
  height: auto;
  justify-content: space-around;
  position: relative;
  width: 100%;
}

.c {
  display: none !important;
  height: auto;
  left: 4.7vw;
  margin-left: -1vw;
  margin-right: 0vw;
  position: absolute;
  top: 3.5vw;
  width: auto;
  z-index: 100;
}

@media (min-width: 601px) {
  .App-headerImg3 {
    display: block;
    height: 8vw;
    left: 4.7vw;
    margin-left: -1vw;
    margin-right: 0vw;
    position: fixed;
    top: 3.5vw;
    width: auto;
    z-index: 115;
  }
}

.dropsFrontCardContainer {
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  margin-right: 2vw;
  position: relative;
}

.dropsFrontcardContainerDivSpan {
  display: flex;
  flex-direction: column;
  height: 7vw;
  justify-content: center;
  position: relative;
}

.dropsFrontCardContainerimg {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  height: 29vw;
  object-fit: cover;
  width: 54vw;
}

.dropsFrontCardContainerDiv {
  align-items: center;
  background: white;
  border-radius: 8px;
  border-top: 0.5vw solid #ffc007;
  bottom: -20%;
  display: flex;
  justify-content: center;
  position: absolute;
  width: 100%;
  z-index: 4;
}

.dropsFrontCardContainerSpan {
  color: var(--cor-primaria);
  font-family: "Rubik", sans-serif;
  font-size: calc(1.5vw + 0.2vw);
  font-weight: 500;
  margin-inline: 2vw;
  position: relative;
  text-align: center;
  z-index: 5;
}

.dropsFrontCardContainerSpanCartola {
  align-items: center;
  align-self: center;
  background-color: #ffc007;
  border-radius: 24px;
  bottom: 87%;
  color: #000;
  display: flex;
  font-family: "Rubik", sans-serif;
  font-size: 2vw;
  font-style: normal;
  font-weight: 400;
  height: 2vw;
  justify-content: center;
  line-height: normal;

  padding-inline: 1vw;
  position: absolute;
  text-overflow: ellipsis;
  z-index: 6;
}

.App-header span {
  color: #fff;
  font-family: "Rubik", sans-serif;
  font-size: calc(1.25vw + 0.2vw);
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-inline: 1vw;
}

.ContainerRow {
  align-items: center;
  display: flex;
  justify-content: space-around;
  width: 100%;
}

.ContainerRow span {
  color: var(--cor-primaria);
  font-family: "Rubik", sans-serif;
  font-size: calc(1.25vw + 0.2vw);
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.InnerContainerRow {
  align-items: center;
  display: flex;
  justify-content: space-around;
  width: auto;
}

.InnerContainerRow1 {
  align-items: center;
  background-color: var(--cor-primaria);
  display: flex;
  gap: 4vw;
  justify-content: center;
  padding: 1vw;
  padding-inline: 5vw;
  width: 100%;
  display: none;
}

.containerPlayer {
  display: flex;
  justify-content: center;
  position: relative;
}

.App-Player.home {
  stroke: none;
  bottom: auto;
  top: -8vw !important;
  width: 100%;
  transform: scale(1);
  height: 8vw;
  padding-top: 0vw;
  margin-left: 0vw;
  padding-inline: 0vw;
  background-color: white;
  border-radius: 8px;
  right: 0;
  padding: 0vw;
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;
  padding-top: 2px;
  padding-right: 10px;
  padding-bottom: 0px;
  padding-left: 10px;
  display: flex;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  position: fixed;
  transition: top 0.5s ease, border-radius 0.5s ease;
  z-index: 999;
}

.App-Player.playing {
  stroke: none;
  bottom: auto;
  top: 0vw !important;
  width: 100%;
  transform: scale(1);
  height: 8vw;
  padding-top: 0vw;
  margin-left: 0vw;
  padding-inline: 0vw;
  background-color: white;
  border-radius: 8px;
  right: 0;
  padding: 0vw;
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;
  padding-top: 2px;
  padding-right: 10px;
  padding-bottom: 0px;
  padding-left: 10px;
  display: flex;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  position: fixed;
  transition: top 0.5s ease, border-radius 0.5s ease;
  z-index: 999;
}

@media (min-width: 600px) {
  .App-Player.playing {
    padding-left: 27px;
    height: 5vw;
    align-items: center;
    justify-content: center;
  }
}

.App-Player.principios-editoriais-page {
  /*Adicionesuasestilizaçõesaqui*/
  top: 1.8vw;
  transform: scale(1);
  height: 2vw;
  padding-top: 1vw;
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
}

.App-Player.sobre-page {
  /*Adicionesuasestilizaçõesaqui*/
  top: 0.2vw;
  transform: scale(1.1);
  height: 6vw;
  padding-top: 0.3vw;
  -webkit-transform: scale(1.1);
  -moz-transform: scale(1.1);
  -ms-transform: scale(1.1);
  -o-transform: scale(1.1);
}

.App-Player.noticias-page {
  /*Adicionesuasestilizaçõesaqui*/
  top: 0.8vw;
  transform: scale(1.4);
  height: 6vw;
  padding-top: 0.3vw;
  -webkit-transform: scale(1.4);
  -moz-transform: scale(1.4);
  -ms-transform: scale(1.4);
  -o-transform: scale(1.4);
}

@media (max-width: 600px) {
  .App-Player.home {
    stroke: none;
    bottom: auto;
    top: -15vw !important;
    width: 100%;
    transform: scale(1);
    height: 15vw;
    padding-top: 0vw;
    margin-left: 0vw;
    padding-inline: 0vw;
    background-color: white;
    border-radius: 8px;
    right: 0;
    padding: 0vw;
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    padding-top: 2px;
    padding-right: 10px;
    padding-bottom: 0px;
    padding-left: 10px;
    display: flex;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    position: fixed;
    transition: top 0.5s ease, border-radius 0.5s ease;
    z-index: 999;
  }

  .App-Player.sobre-page {
    /*Adicionesuasestilizaçõesaqui*/
    top: 1.8vw;
    transform: scale(1.7);
    height: 8vw;
    padding-top: 1vw;
    -webkit-transform: scale(1.7);
    -moz-transform: scale(1.7);
    -ms-transform: scale(1.7);
    -o-transform: scale(1.7);
  }

  .App-Player.playing {
    stroke: none;
    bottom: auto;
    top: 0vw !important;
    width: 100%;
    transform: scale(1);
    height: 15vw;
    padding-top: 0vw;
    margin-left: 0vw;
    padding-inline: 0vw;
    background-color: white;
    border-radius: 8px;
    right: 0;
    padding: 0vw;
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    padding-top: 2px;
    padding-right: 10px;
    padding-bottom: 0px;
    padding-left: 10px;
    display: flex;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    position: fixed;
    transition: top 0.5s ease, border-radius 0.5s ease;
    z-index: 999;
  }

  .App-Player.principios-editoriais-page {
    /* Adicione suas estilizações aqui */
    top: 1.8vw;
    transform: scale(1.7);
    height: 8vw;
    padding-top: 1vw;
    /* Exemplo */
  }

  .App-Player.noticias-page {
    top: 4vw;
    transform: scale(2);
    height: 8vw;
    padding-top: 0.5vw;
    -webkit-transform: scale(2);
    -moz-transform: scale(2);
    -ms-transform: scale(2);
    -o-transform: scale(2);
  }

  .App-header img:first-child {
    height: 14vw;
    left: vw;
    margin-left: 0vw;
    margin-right: 0vw;
    top: 0vw;
    width: 22vw;
    z-index: 22;
  }

  .App-Player {
    border-bottom-left-radius: 18px;
    border-bottom-right-radius: 18px;
    height: 6vw;
    margin-top: 0vw;
    top: 0vw;
    transform: scale(1);
    width: 100%;
  }

  .App-Player.contato-page {
    bottom: 0rem !important;
    top: auto;
    width: 100%;
    transform: scale(1);
    height: 15vw;
    padding-top: 0vw;
    margin-left: 0vw;
    padding-inline: 0vw;
    background-color: var(--cor-primaria);
    border-radius: 8px;
    right: -84%;
    padding: 0vw;
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    padding-top: 2px;
    /*Adicionapaddingaotopo*/
    padding-right: 10px;
    /*Adicionapaddingàdireita*/
    padding-bottom: 0px;
    /*Adicionapaddingaofundo*/
    padding-left: 10px;
    display: flex;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    transition: all 0.5s ease;
  }

  .App-Player.home {
    position: relative;
    bottom: auto;
    top: -14vw !important;
    width: 100%;
    transform: scale(1);
    height: 15vw;
    padding-top: 0vw;
    margin-left: 0vw;
    padding-inline: 0vw;
    background-color: white;
    border-radius: 0px;
    right: 0%;
    padding: 0vw;
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    -webkit-border-radius: 0px;
    -moz-border-radius: 0px;
    -ms-border-radius: 0px;
    -o-border-radius: 0px;
    padding-top: 2px;
    /*Adicionapaddingaotopo*/
    padding-right: 10px;
    /*Adicionapaddingàdireita*/
    padding-bottom: 0px;
    /*Adicionapaddingaofundo*/
    padding-left: 10px;
    display: flex;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    transition: top 0.5s ease, border-radius 0.5s ease, height 0.5s ease;
    position: fixed;
    -webkit-transition: top 0.5s ease, border-radius 0.5s ease;
    -moz-transition: top 0.5s ease, border-radius 0.5s ease;
    -ms-transition: top 0.5s ease, border-radius 0.5s ease;
    -o-transition: top 0.5s ease, border-radius 0.5s ease;
  }

  .App-Player.playing {
    stroke: none;
    bottom: auto;
    top: -0vw !important;
    width: 100%;
    transform: scale(1);
    height: 15vw;
    padding-top: 0vw;
    margin-left: 0vw;
    padding-inline: 0vw;
    background-color: white;
    border-radius: 8px;
    right: 0;
    padding: 0vw;
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    padding-top: 2px;
    /*Adicionapaddingaotopo*/
    padding-right: 10px;
    /*Adicionapaddingàdireita*/
    padding-bottom: 0px;
    /*Adicionapaddingaofundo*/
    padding-left: 10px;
    display: flex;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    position: fixed;
    transition: top 0.5s ease, border-radius 0.5s ease;
    z-index: 999;
  }

  .App-header {
    display: flex;
    flex-direction: column;
    margin-top: 7vw;
    text-align: center;
  }
}

.Container-Music-Title {
  display: flex;
  flex-direction: column;
  width: auto;
  max-width: 56vw;
}

@media (max-width: 426px) {
  .Container-Music-Title {
    display: flex;
    flex-direction: column;
    width: auto;
    max-width: 42vw;
  }
}

.ContainerMusicSpanPlaying {
  animation: marquee 10s linear infinite;
  color: white;
  overflow: hidden;
  position: relative;
  text-overflow: clip;
  white-space: nowrap;
  width: 45vw;
  font-size: 13px;
  -webkit-animation: marquee 10s linear infinite;
}

@media (min-width: 601px) {
  .Container-Music-Title {
    display: flex;
    flex-direction: column;
    width: auto;
    max-width: 50rem;
    margin-right: 2rem;
  }

  .ContainerMusicSpanPlaying {
    animation: marquee 10s linear infinite;
    color: white;
    overflow: hidden;
    position: relative;
    text-overflow: clip;
    white-space: nowrap;
    width: auto;
    font-size: 13px;
    -webkit-animation: marquee 10s linear infinite;
  }
}

.programaAtual {
  height: auto !important;
  left: 4.7vw !important;
  margin-left: 0vw !important;
  margin-right: 0vw !important;
  position: inherit !important;
  top: 3.5vw !important;
  width: 1.5vw !important;
  z-index: 1 !important;
}

.programaAtual.sidebar-open {
  display: block !important;
  height: 1.2vw !important;
  left: 4.7vw !important;
  margin-left: 0vw !important;
  margin-right: 0vw;
  position: inherit !important;
  top: 3.5vw !important;
  width: 1.5vw !important;
  width: 4vw !important;
  z-index: 1 !important;
}

.App-header.sidebar-open img:first-child {
  height: auto;

  left: 4vw;
  margin-left: 0vw;
  margin-right: 45vw;
  top: 5vw;
  width: 42vw;
  z-index: 22;
}

.programaAtual2 {
  height: auto !important;
  left: 4.7vw !important;
  margin-left: 0vw !important;
  margin-right: 0vw;
  position: inherit !important;
  top: 3.5vw !important;
  width: 2.8vw !important;
  z-index: 1 !important;
}

.programaAtual2.sidebar-open {
  display: block;
  height: auto !important;
  left: 4.7vw !important;
  margin-left: 0vw !important;
  margin-right: 0vw;
  position: inherit !important;
  top: 3.5vw !important;
  width: 1.5vw !important;
  width: 4vw !important;
  z-index: 1 !important;
}

.ContainerMusicSpanPlaying span {
  align-items: center;
  color: var(--cor-primaria);
  display: flex;
  font-family: "Rubik", sans-serif;
  font-size: 3rem;
  font-weight: 400;
  margin-left: 0vw;
  padding-right: 85%;
  max-width: auto;
}

@media (min-width: 600px) {
  .ContainerMusicSpanPlaying span {
    font-size: 1.4rem;
    /* Insira as regras CSS que você deseja aplicar acima de 600px aqui */
  }
}

.ContainerMusicSpanPlaying.static {
  animation: none !important;
}

.ContainerMusicSpanPlaying span.static {
  align-items: center;
  color: white;
  display: flex;
  font-family: "Rubik", sans-serif;
  font-size: 1.4vw;
  font-weight: 400;
  margin-left: 0vw;
  padding-right: 70%;
}

.Container-Music-TitleSpan {
  color: var(--cor-primaria);
  font-family: "Rubik", sans-serif;
  font-size: 1.4rem;
  font-weight: 200;
  text-align: left !important;
}

@media (max-width: 600px) {
  .ContainerMusicSpanPlaying span.static {
    align-items: center;
    color: white;
    display: flex;
    font-family: "Rubik", sans-serif;
    font-size: 1.4vw;
    font-weight: 400;
    margin-left: 0vw;
    padding-right: 90%;
  }

  .Container-Music-TitleSpan {
    color: var(--cor-primaria);
    font-family: "Rubik", sans-serif;
    font-size: 1rem;
    font-weight: 200;
    text-align: left !important;
  }

  .ContainerMusicSpanPlaying span {
    align-items: center;
    color: #541084;
    display: flex;
    font-family: "Rubik", sans-serif;
    font-size: 0.6rem;
    font-weight: 400;
    margin-left: 0vw;
    padding-right: 85%;
    max-width: 0vw;
  }
}

@keyframes marquee {
  0%,
  50% {
    text-indent: 0%;
  }

  100% {
    text-indent: -100%;
  }
}

.Container-Music-Title span:nth-child(3) {
  font-size: 1.7vw;
}

.ContainerRadioList {
  width: auto;
}

.App-link {
  color: #61dafb;
}

.ContainerRadioList select {
  appearance: none;
  background: none;
  border: none;
  color: var(--cor-primaria);
  font-family: "Rubik", sans-serif;
  font-size: 1.5vw;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding-block: 1rem;
}

.ContainerRadioList select:focus {
  appearance: none;
  background: none;
  background-color: white;
  border: none;
  color: var(--cor-primaria);
  font-family: "Rubik", sans-serif;
  font-size: 1.5vw;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.RadioList::-webkit-scrollbar {
  width: 5vw;
}

.RadioList::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.RadioList::-webkit-scrollbar-thumb {
  background: #888;
}

.RadioList::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.RadioItem:first-child {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.RadioItem:last-child {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.RadioItem {
  align-items: center;
  background-color: white;
  display: flex;
  height: 30px;
  justify-content: center;
  margin-left: 1vw;
  max-height: 200px;
  overflow-y: auto;
  width: 100%;
}

.VolumeControl {
  position: relative;
}

.App-Player input[type="range"] {
  background: transparent;
  bottom: -5px;
  cursor: pointer;
  left: 50px;
  position: absolute;
  webkit-appearance: none;
  width: 200px;
}

.App-Player input[type="range"]::-webkit-slider-runnable-track {
  background: white;
  border-radius: 4px;
  cursor: pointer;
  height: 4px;
  width: 100%;
}

.App-Player input[type="range"]::-webkit-slider-thumb {
  appearance: none;
  background: #9248ff;
  border-radius: 5px;
  cursor: pointer;
  height: 10px;
  margin-top: -3px;
  webkit-appearance: none;
  width: 10px;
}

.central-container {
  align-items: center;
  display: flex;
  margin-top: 1vw;
}

.central-container {
  align-items: center;
  display: flex;
  justify-content: space-around;
}

.center-image {
  gap: 2vw;
  height: auto;
  margin-inline: 4.2vw;
  object-fit: cover;
  width: 12vw;
}

.side-image {
  height: auto;
  object-fit: cover;
  width: 26vw;
}

.side-image1 {
  height: auto;
  margin-right: 18vw;
  object-fit: cover;
  width: 25vw;
}

.center-image-container {
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 1vw;
  justify-content: space-around;
}

.progContainer {
  align-items: center;
  /* background-color: rgb(255, 255, 255); */
  /* background-image: url('./AssetDrops/fundoProg.jpg'); */
  /* background: radial-gradient(circle at 50% 40%, #541084, #6f23a1, #8c35be, #a948db, #c65bf8); */
  background-color: #f4e72d;
  /* background-color: #FFD300; */
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  height: auto;
  /* padding-bottom: 7vw;
  padding-inline: 6vw; */
  position: relative;
  width: 100%;
  z-index: 15;
}

.progContainerRow {
  align-items: center;
  display: flex;
  flex-direction: row;
  height: auto;
  justify-content: center;
  margin-inline: 1vw;
  position: relative;
  width: 100%;
  z-index: 15;
}

.progContainerColumn {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-inline: 1vw;
  width: auto;
}

.specialHeight {
  height: 6vw;
}

.progCardContainerRow {
  align-items: center;
  background-color: white;
  border-radius: 500px;
  display: flex;
  flex-direction: row;
  height: auto;
  justify-content: center;
  margin-inline: 1vw;
  padding-right: 2vw;
  position: relative;
}

.progCardContainerColumn {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-inline: 1vw;
  width: auto;
}

.progCardContainerRow span {
  color: #1e1e1e;
  font-family: "Rubik", sans-serif;
  font-size: 0.7vw;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: center;
  width: 7vw;
}

.progCardContainerRow span:nth-child(1) {
  color: #2d0b3e;
}

.progCardContainerRow span:nth-child(2) {
  color: green;
  margin-block: 0.1vw;
}

.progCardContainerRow span:nth-child(3) {
  color: #2d0b3e;
  font-size: 0.6vw;
}

.progBallContainer {
  align-items: center;
  background-color: white;
  border-radius: 50%;
  display: flex;
  height: 6vw;

  width: 6vw;
}

.progBallContainer img {
  align-items: center;
  background-color: white;
  display: flex;
  height: 5vw;
  margin-left: 1vw;
  object-fit: fill;
  width: 5vw;
}

.progBallContainer.specialHeightDeuBo img {
  object-fit: contain !important;
}

.progBallContainer.specialHeightSlowMo img {
  height: 2vw;

  position: relative;
  top: 0vw;
  width: 2vw;
}

.progBallContainer.specialHeightFestaPlus img {
  height: 8vw;
  left: -1.5vw;
  position: relative;
  top: 1vw;
  width: 8vw;
}

.progImage {
  align-self: flex-start;
  height: 7vw;
  margin-block: 5vw;
  margin-left: 4.5vw;
  margin-top: 2vw;
  z-index: 5;
  display: none;
}

.mapImage {
  align-self: flex-start;
  height: 7vw;
  margin-block: 2vw;
  margin-bottom: 6vw;
  margin-left: 10.5vw;
  z-index: 5;
}

.dropsImage {
  align-self: flex-start;
  height: 7vw;

  margin-block: 2vw;
  margin-top: 5vw;
  margin-left: 8.5vw;
  z-index: 5;
}

.programasImage {
  align-self: flex-start;
  display: flex;
  height: 8vw;
  margin-bottom: 4vw;
  margin-left: 9.5vw;
  margin-top: 6vw;
  z-index: 5;
}

@media (max-width: 600px) {
  .progContainer {
    align-items: center;
    /* background-color: rgb(255, 255, 255); */
    /* background-image: url('./AssetDrops/fundoProg.jpg'); */
    background: radial-gradient(
      circle at 50% 40%,
      #541084,
      #6f23a1,
      #8c35be,
      #a948db,
      #c65bf8
    );
    /* background-color: #F4e72d; */
    /* background-color: #FFD300; */
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    flex-direction: column;
    height: auto;
    padding-bottom: 7vw;
    padding-inline: 6vw;
    position: relative;
    width: 100%;
    z-index: 15;
  }

  .ContainerRadioList {
    width: auto;
    margin: auto;
  }

  .programasImage {
    align-self: flex-start;
    display: flex;
    height: 14vw;
    margin-bottom: 6vw;
    margin-left: 5.5vw;
    margin-top: 2vw;
    z-index: 5;
  }

  .mapImage {
    align-self: flex-start;
    height: 12vw;
    margin-block: 2vw;
    margin-bottom: 6vw;
    margin-left: 4.5vw;
    z-index: 5;
  }

  .progImage {
    align-self: flex-start;
    height: 12vw;
    margin-block: 5vw;
    margin-left: 0.5vw;
    margin-top: 2vw;
    z-index: 5;
    display: block;
  }
}

.textProgImg {
  align-self: flex-start;
  height: 7vw;
  margin-left: 10.5vw;
  z-index: 5;
}

.marginDiv {
  margin-top: 1vw;
}

.promoContainer {
  align-items: center;
  background-image: url("./AssetDrops/fundoPromo.png");
  background-repeat: no-repeat;
  background-size: cover;
  background: radial-gradient(
    circle 57vw at center,
    var(--cor-backgroundEscuro),
    var(--cor-backgroundClaro)
  );
  display: flex;
  flex-direction: column;
  height: auto;
  justify-content: center;
  margin-inline: 1vw;
  padding-top: 2vw;
  width: 100%;
}

.promoContainer span {
  color: var(--cor-primaria);
  font-family: "Rubik", sans-serif;
  font-size: 0.9vw;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding-inline: 0.6vw;
  z-index: 5;
}

.promoContainerRow {
  align-items: center;
  display: flex;
  flex-direction: row;
  height: auto;
  justify-content: center;
  margin-inline: 1vw;
  padding-top: 1vw;
  width: auto;
}

.promoActorRow {
  align-items: center;
  display: flex;
  flex-direction: row;
  gap: 11vw;
  height: auto;
}

@media (max-width: 600px) {
  .actorImage {
    display: none;
  }

  .promoContainer span {
    color: var(--cor-primaria);
    font-family: "Rubik", sans-serif;
    font-size: 1.1vw;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    padding: 0.2vw;
    padding-inline: 1vw;
    z-index: 5;
  }

  .promoContainer {
    align-items: center;
    /* background-image: url('./AssetDrops/fundoPromo.png');
    background-repeat: no-repeat;
    background-size: cover; */
    /* background: radial-gradient(circle 50vw at center, var(--cor-backgroundEscuro), var(--cor-backgroundClaro)); */
    display: flex;
    flex-direction: column;
    height: auto;
    justify-content: center;
    margin-inline: 1vw;
    padding-top: 2vw;
    width: 100%;
    height: 110vw;
    position: relative;
  }

  .textProgImg {
    align-self: flex-start;
    height: 12vw;
    margin-left: 7.5vw;
    z-index: 5;
    position: absolute;
    top: 2vw;
  }

  .promoActorRow {
    align-items: center;
    display: flex;
    flex-direction: row;
    gap: 11vw;
    height: auto;
    transform: scale(2.3);
    -webkit-transform: scale(2.3);
    -moz-transform: scale(2.3);
    -ms-transform: scale(2.3);
    -o-transform: scale(2.3);
    position: absolute;
  }
}

.promoCardBig {
  align-items: center;
  display: flex;
  flex-direction: row;
  height: auto;
  justify-content: center;
  margin-inline: 0.5vw;

  width: auto;
}

.promoActorRow img {
  height: 35vw;
  object-fit: contain;
  width: auto;
}

.actorImage {
  margin-top: 6vw;
  padding-top: 4vw;
}

.promoCardBig img {
  border-radius: 10px;
  height: 22vw;
  width: auto;
}

.promoCardSmall {
  background-color: white;
  border-radius: 10px;
  box-shadow: 0px 5px 8px rgba(0, 0, 0, 0.2);
  height: 22vw;
  margin: 0 0.5vw;

  width: auto;
}

.promoCardSmall img {
  border-radius: 10px;
  height: 22vw;
  width: auto;
}

.promoContainerColumn {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-inline: 1vw;
  padding-top: 1vw;
  width: auto;
}

.promoCardBig,
.promoCardSmall {
  position: relative;
}

.cartolaAbsolute {
  background-color: var(--cor-terciaria);
  border-radius: 24px;
  color: var(--cor-primaria);
  font-family: "Rubik", sans-serif;
  font-size: 3vw;
  left: 0;
  margin: 0.5rem;
  position: absolute;
  top: 0;
  width: auto;
  z-index: 5;
}

.top10Container {
  align-items: center;
  background-color: #21cdd1;
  display: flex;
  flex-direction: column;
  height: auto;
  justify-content: center;
  width: 100%;
}

.imgTop10 {
  align-self: flex-start;
  height: 15vw;
  margin-bottom: 2vw;
  margin-left: 2vw;
  object-fit: cover;
  width: 45vw;
}

.top10CardsContainer {
  align-items: center;
  display: flex;
  justify-content: center;
  position: relative;
}

.mainCard {
  background-color: white;
  border-radius: 2vw;
  height: 20vw;
  margin-block: 20vw;
  position: relative;
  width: 40vw;
}

.mainCard img {
  height: 100%;
  object-fit: cover;

  width: 100%;
}

.smallCard {
  background-color: white;
  border-radius: 1vw;
  height: 10vw;
  position: absolute;
  width: 20vw;
}

.mainCard img:hover,
.smallCard hover {
  transform: scale(1.1);
  transition: transform 0.3s ease-in-out;
}

.smallCard img {
  height: 100%;
  max-width: 100%;
  object-fit: cover;
  width: 100%;
}

.smallCard1 {
  left: 105%;
  top: 50%;
}

.smallCard2 {
  height: 15vw;
  left: 105%;
  top: -35%;
  width: 25vw;
}

.smallCard3 {
  left: 50%;
  top: -60%;
}

.smallCard4 {
  height: 15vw;
  left: -18%;
  top: -85%;
  width: 25vw;
}

.smallCard5 {
  height: 12vw;
  left: -60%;
  top: 0%;
  width: 22vw;
}

.smallCard6 {
  left: -55%;
  top: 70%;
}

.smallCard7 {
  height: 12vw;
  left: 0%;
  top: 110%;
  width: 22vw;
}

.smallCard8 {
  left: 60%;
  top: 110%;
}

.smallCard9 {
  height: 9vw;
  left: 115%;
  top: 110%;
  width: 19vw;
}

x @media (max-width: 600px) {
  .dropsImage {
    height: 0vw;
    margin-left: 5.8vw;
    margin-bottom: 0vw;
  }

  .cartolaAbsolute {
    margin: 0.3rem;
  }

  .dropsContainerNoticias {
    border-radius: 24px;
    display: flex;
    flex-direction: row;
  }

  .dropsContainer {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 2vw;
  }

  .dropsFrontCardContainer {
    margin-right: 0vw;
  }

  .dropsFrontCardContainerDiv {
    align-items: center;
    background: white;
    border-radius: 8px;
    border-top: 0.5vw solid #ffc007;
    bottom: 0%;
    display: flex;
    justify-content: center;
    position: absolute;
    width: auto;
    z-index: 4;
  }

  .dropsFrontCardContainerimg {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    height: 59.3vw;
    margin-bottom: 1vw;
    object-fit: cover;
    width: 88vw;
  }

  .dropsFrontcardContainerDivSpan {
    display: flex;
    flex-direction: column;
    height: 7vw;
    justify-content: center;
    position: relative;
    width: 88vw;
  }

  .dropsRowContainer img {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    height: 25vw;
    margin-top: 4.5vw;
    object-fit: cover;

    width: 41.4vw;
  }

  .first-row {
    margin-bottom: 5.5vw;
    margin-right: 5vw;
    width: 41.4vw;
  }

  .dropsColumnContainer span {
    bottom: -14%;
    font-size: calc(1.9vw + 0.2vw);
    margin-inline: 1vw;
    position: absolute;
    text-align: center;
    z-index: 5;
  }

  .spanContainerNoticias {
    bottom: -20%;
    color: var(--cor-primaria);
    font-family: "Rubik", sans-serif;
    font-size: calc(2vw + 0.2vw);
    margin-inline: 1vw;
    position: absolute;
    text-align: center;
    z-index: 5;
  }

  .dropsFrontCardContainerSpanCartola {
    font-size: 2.5vw;
    height: 2.7vw;
    padding-inline: 1.5vw;
  }

  .dropsFrontCardContainerSpan {
    font-size: calc(2vw + 0.2vw);
  }

  .dropsNoArContainer {
    font-size: 2vw;
    height: 2.5vw;
    padding-inline: 2vw;
  }
}

.footer {
  align-items: center;
  background-color: var(--cor-primaria);
  display: flex;
  gap: 15vw;
  height: 45vw;
  justify-content: center;
  padding: 20px;
  width: 100%;
  /* border-radius: 12px; */
  position: relative;
}

.footerDiv {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 10px;
}

@media (min-width: 601px) {
  .footerDiv {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 0 10px;
    padding: 0 10px;
    /* Adicione padding conforme necessário */
  }

  .footer {
    align-items: center;
    background-color: #9248ff;
    display: flex;
    gap: 15vw;
    height: 21vw;
    justify-content: space-between;
    padding: 20px;
    padding-inline: 10rem;
    width: 100%;
    position: relative;
  }
}

.footerDiv1 {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 10px;
  margin-right: 6vw;
}

.footerImage1 {
  height: auto;
  margin: 0 10px;
  margin-block: 0.5vw;
  object-fit: cover;
  width: 19vw;
}

.footerImage2 {
  height: auto;
  margin: 0 10px;
  object-fit: cover;
  width: 18vw;
}

.footerImage4 {
  height: auto;
  margin: 0 10px;
  object-fit: cover;
  width: 14vw;
  margin-bottom: 1vw;
}

.footerImage3 {
  height: auto;
  margin: 0 10px;
  object-fit: contain;
  width: 14vw;
  display: none;
}

.footerImage4 {
  height: auto;
  margin: 0px;
  object-fit: cover;
  width: 10rem;
  align-self: start;
}

@media (max-width: 600px) {
  .footerImage3 {
    height: 50px;
    margin: 0 10px;
    object-fit: contain;
    width: 14vw;
  }

  .footerDivRow {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 1vw;
  }

  .footerImage4 {
    height: auto;
    margin: 0 10px;
    object-fit: cover;
    width: 30vw;
    margin-bottom: 5vw;
    align-self: center;
  }
}

.footerText {
  color: #ffffff;
  font-family: "Rubik", sans-serif;
  font-size: 1.2rem;

  font-style: normal;
  font-weight: 200;
  line-height: normal;
  margin-block: 2vw;
  z-index: 5;
  text-align: center;
  position: absolute;
  bottom: 0%;
  left: 50%;
  transform: translate(-50%, -50%);
  align-self: center;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
}

@media (max-width: 600px) {
  .footerText {
    color: #ffffff;
    font-family: "Rubik", sans-serif;
    font-size: 13px;
    font-style: normal;
    font-weight: 200;
    line-height: normal;
    margin-block: 1vw;
    z-index: 5;
  }
}

.nossoStyle {
  color: #ffc007;
  font-family: "Rubik", sans-serif;
  font-size: 3vw;
  font-weight: 400;
}

.nossoStyle1 {
  font-family: "Rubik", sans-serif;
  font-size: 3vw;
  font-weight: 200;
  margin-right: 4vw;
}

.appStyle {
  font-family: "Rubik", sans-serif;
  font-size: 3vw;
  font-weight: 200;
  margin-right: 3vw;
}

.imageContainer {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 1vw;
  justify-content: center;
  margin-block: 3vw;
}

.imageContainer1 {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: left;
}

.contato {
  align-items: center;
  background-color: var(--cor-primaria);
  color: #ffffff;
  display: flex;
  flex-direction: row;
  gap: 16vw;
  justify-content: center;
  padding-block: 5vw;
  text-align: center;
  width: 100%;
  display: none;
}

@media (max-width: 600px) {
  .imageContainer {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 1vw;
    justify-content: center;
    margin-block: 3vw;
  }

  .footerText {
    color: #ffffff;
    font-family: "Rubik", sans-serif;
    font-size: 1rem;
    font-style: normal;
    font-weight: 200;
    line-height: normal;
    margin-block: 1vw;
    z-index: 5;
  }

  .contato {
    align-items: center;
    background-color: var(--cor-primaria);
    color: #ffffff;
    display: flex;
    flex-direction: row;
    gap: 0vw;
    justify-content: center;
    padding-block: 5vw;
    text-align: center;
    width: 100%;
    display: none;
  }
}

.contato h2 {
  color: #ffc007;
  font-family: "Rubik", sans-serif;
  font-size: 4vw;
  font-style: normal;
  font-weight: 200;
  line-height: normal;
  margin-block: 1vw;
  z-index: 5;
}

@media (max-width: 600px) {
  .contato h2 {
    font-size: 4vw;
  }

  .dropsNoArContainer-0 {
    bottom: 8%;
  }

  .dropsNoArContainer-1 {
    bottom: 8%;
  }
}

.contato h4 {
  color: #ffffff;
  font-family: "Rubik", sans-serif;
  font-size: 2.2vw;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0.1vw;
  line-height: normal;
  z-index: 5;
}

.contato h7 {
  color: #ffffff;
  font-family: "Rubik", sans-serif;
  font-size: 2.2vw;
  font-style: normal;
  font-weight: 200;
  letter-spacing: 0.1vw;
  line-height: normal;
  z-index: 5;
}

.contato h6 {
  color: #ffffff;
  font-family: "Rubik", sans-serif;
  font-size: 2.2vw;
  font-style: normal;
  font-weight: 200;
  letter-spacing: 0vw;
  line-height: normal;
  z-index: 5;
}

.contato h5 {
  color: #ffffff;
  font-family: "Rubik", sans-serif;
  font-size: 2.2vw;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0vw;
  line-height: normal;
  opacity: 1;
  z-index: 5;
}

.center-divP-bigode {
  display: none;
}

.second-news-container {
  display: flex;
  flex-direction: column;
  gap: 1vw;
}

.center-divP-date {
  display: none;
}

.contato h8 {
  color: #ffffff;
  font-family: "Rubik", sans-serif;
  font-size: 2.2vw;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0vw;
  line-height: normal;
  z-index: 5;
}

.contato h4:nth-child(1) {
  letter-spacing: 0vw;
}

.contatoInfo p {
  font-family: "Rubik", sans-serif;
  font-size: 1.5vw;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 5px 0;
  margin-block: 1vw;
  z-index: 5;
}

.contatoInfo {
  display: flex;
  flex-direction: column;
  gap: 1vw;
  justify-content: center;
  margin-block: 3vw;
  display: none;
}

.redesSociaisP {
  color: #ffffff;
  font-family: "Rubik", sans-serif;
  font-size: 2.2vw;
  font-style: normal;
  font-weight: 200;
  margin-block: 1vw;
  z-index: 5;
  display: none;
}

.dropsText {
  display: none;
}

.dropsText1 {
  display: none;
}

.dropsText2 {
  display: none;
}

@media (max-width: 600px) {
  .imageContainer1 {
    display: flex;
    flex-direction: column;
  }

  .footerDiv1 {
    margin-right: 16vw;
  }
}

.container {
  display: flex;
  flex-direction: column;
  gap: 5vw;
  justify-content: space-between;
}

@media (min-width: 601px) {
  .backgroundDivContainerNews {
    background-color: var(--cor-primaria);
    border-radius: 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    z-index: 1;
    width: 100%;

    -webkit-border-radius: 0px;
    -moz-border-radius: 0px;
    -ms-border-radius: 0px;
    -o-border-radius: 0px;
  }
}

.sub-container {
  position: relative;
}

.sub-container img {
  border-radius: 10px;
  height: 14vw;
  object-fit: cover;
  width: 14vw;
}

.absolute-div {
  align-items: center;
  align-self: center;
  background-color: var(--cor-primaria);
  border-radius: 8px;
  display: flex;
  height: 100%;
  height: 2vw;
  justify-content: center;
  position: absolute;
  top: -1vw;
  z-index: 5;
}

.center-div {
  align-items: center;
  background-color: white;
  border-radius: 8px;
  border-top: 6px solid var(--cor-primaria);
  bottom: -25%;
  display: flex;
  height: 7vw;
  justify-content: center;
  left: 50%;
  padding-block: 1vw;
  position: absolute;
  transform: translate(-50%, -50%);
  width: 100%;
}

.contentItem img {
  height: 22vw;
  width: 100%;
  border-radius: 12px;
  object-fit: cover;
  -webkit-border-radius: 12px;
  -moz-border-radius: 12px;
  -ms-border-radius: 12px;
  -o-border-radius: 12px;
}

@media (max-width: 600px) {
  .contentItem img {
    height: 52vw;
    width: 100%;
    border-radius: 12px;
    object-fit: cover;
  }

  .absolute-div {
    align-items: center;
    align-self: center;
    background-color: var(--cor-primaria);
    border-radius: 8px;
    display: flex;
    height: 100%;
    height: 2vw;
    justify-content: center;
    position: absolute;
    top: -1.5vw;
    z-index: 5;
  }
}

.center-divPP {
  color: white;
  font-family: "Rubik", sans-serif;
  font-size: 18px;
  margin-inline: 2vw;
  text-align: center;
}

.center-divP {
  color: var(--cor-primaria);
  font-family: "Rubik", sans-serif;
  font-size: 1.1rem;
  margin-inline: 1vw;
  text-align: left;
  font-weight: 400;
}

@media (max-width: 600px) {
  .second-news .center-div {
    align-items: center;
    background-color: white;
    border-radius: 8px;
    border-top: 6px solid var(--cor-primaria);
    display: flex;
    height: 24.5vw;
    justify-content: center;
    left: 217%;
    padding-block: 1vw;
    position: absolute;
    transform: translate(-50%, -50%);
    width: 41vw;
    border-radius: 6px;
    bottom: -44%;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;
    border-top: 6px solid transparent;
  }

  .sub-container img {
    border-radius: 8px;
    height: 25vw;
    object-fit: cover;
    width: 41vw;
  }

  .first-news .center-divP {
    color: black;
    font-family: "Rubik", sans-serif;
    font-size: 3.3vw;
    font-weight: 200;
    margin-inline: 2vw;
    text-align: center;
  }

  .first-news .absolute-div {
    align-items: center;
    align-self: center;
    background-color: var(--cor-primaria);
    border-radius: 8px;
    display: flex;

    height: 4vw;
    justify-content: center;
    position: absolute;
    top: -30%;
    z-index: 5;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    font-weight: 200;
    width: auto;
    padding: 2.5vw;
  }

  .first-news .center-divPP {
    color: white;
    font-family: "Rubik", sans-serif;
    font-size: 3.8vw;
    margin-inline: 2vw;
    text-align: center;
  }

  .second-news .center-divPP {
    color: white;
    font-family: "Rubik", sans-serif;
    font-size: 3vw;
    margin-inline: 2.5vw;

    text-align: center;
    font-weight: 200;
    padding-block: 1vw;
  }

  .second-news .center-divP {
    color: black;
    font-family: "Rubik", sans-serif;
    font-size: 2.5vw;
    margin-left: 4vw;
    text-align: left;
    font-weight: 200;
  }

  .second-news .absolute-div {
    align-items: center;
    background-color: var(--cor-primaria);
    border-radius: 8px;
    display: flex;
    height: 100%;
    height: 3vw;
    justify-content: center;
    position: absolute;
    top: -4vw;
    align-self: center;
    z-index: 5;
    width: auto;
    padding: 1vw;
    padding-block: 2.4vw;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    transform: translate(0%, 0%);
    -webkit-transform: translate(0%, 0%);
    -moz-transform: translate(0%, 0%);
    -ms-transform: translate(0%, 0%);
    -o-transform: translate(0%, 0%);
  }

  .container {
    display: flex;
    flex-direction: column;
    gap: 5vw;
    justify-content: space-between;
  }

  .first-news img {
    border-radius: 8px;
    height: 57vw;
    object-fit: cover;
    width: 87.5vw;
  }

  .first-news {
    width: 87.5vw;
    margin-bottom: 1.5vw;
  }

  .second-news {
    width: 30.5vw;
  }

  .second-news img {
    width: 43.5vw;
  }

  .center-div {
    border-radius: 6px;
    bottom: -27%;
  }

  .first-news .center-div {
    height: 16vw;
    border-radius: 8px;
    bottom: -16%;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    border-top: 6px solid var(--cor-primaria);
  }
}

@media (max-width: 500px) {
  .first-news .absolute-div {
    align-items: center;
    align-self: center;
    background-color: var(--cor-primaria);
    border-radius: 8px;
    display: flex;

    height: 4vw;
    justify-content: center;
    position: absolute;
    top: -24%;
    z-index: 5;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    font-weight: 200;
    width: auto;
    padding: 2.5vw;
  }
}

@media (max-width: 466px) {
  .second-news .center-div {
    bottom: -42%;
  }
}

@media (max-width: 316px) {
  .second-news .center-div {
    bottom: -41%;
  }
}

.loader,
.pause-circle,
.play-circle {
  color: var(--cor-primaria);
  font-size: 5rem;
  margin-right: 1vw;
}

.home .DotsPlayer {
  padding-right: 0vw;
  margin-left: 0vw;
  color: white;
  margin-right: 0vw;
  transform: scale(2);
  -webkit-transform: scale(2);
  -moz-transform: scale(2);
  -ms-transform: scale(2);
  -o-transform: scale(2);
  display: none;
}

@media (max-width: 600px) {
  .loader,
  .pause-circle,
  .play-circle {
    padding-right: 0vw;
    margin-left: 0vw;
    color: var(--cor-primaria);
    height: 13vw;
    margin-right: 1vw;
    width: 13vw;
  }

  .home .DotsPlayer {
    padding-right: 0vw;
    margin-left: 0vw;
    color: var(--cor-primaria);
    margin-right: 0vw;
    height: 7vw;
    width: 7vw;
    display: block;
  }

  .home .home .ContainerMusicSpanPlaying {
    width: 0vw;
  }

  loader,
  .pause-circle.noticias-page,
  .play-circle.noticias-page {
    padding-right: 0vw;
    margin-left: 0vw;
    color: var(--cor-primaria);
    height: 13vw;
    margin-right: 1vw;
    width: 13vw;
  }
}

@media (min-width: 601px) {
  .loader,
  .pause-circle,
  .play-circle {
    height: 4vw;
    width: 4vw;
    stroke: none;
  }
}

.circleContainerRow {
  width: 100%;
  margin-top: 0vw;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

@keyframes rotateAndScale {
  0% {
    transform: scale(1) rotate(0deg);
  }
  50% {
    transform: scale(0.95) rotate(10deg);
  }
  100% {
    transform: scale(1) rotate(0deg);
  }
}

.circle-image {
  border-radius: 50%;
  height: 100%;
  left: 0;
  overflow: hidden;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1;
  animation: rotateAndScale 3s infinite ease-in-out;
}

.program-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-items: center;
  padding-left: 0vw;
  text-align: left;
  position: absolute;
  top: 45%;
  left: 50%;
  width: 100%;
  transform: translate(-50%, -50%);
  position: 2000;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  gap: 0.2rem;
}

.button-container {
}

.now-playing {
  font-size: 4vw;
  color: var(--cor-primaria);
  font-family: Rubik;
}

.separator {
  width: 90%;
  border: none;
  border-top: 1px solid var(--cor-terciaria);
  margin: 6px 0;
  margin-left: 5px;
}

.program-title {
  font-size: 3.4vw;
  color: var(--cor-primaria);
  padding: 0vw;
  font-weight: 400;
  text-align: center;
}

.program-time {
  margin-top: 2vw;
  font-family: Rubik;
  font-size: 3vw;
  color: var(--cor-primaria);
  font-weight: 200;
}

.circle-image img {
  height: 100%;
  object-fit: cover;
  width: 100%;
}

.circle-container {
  border-radius: 50%;
  height: 50vw;
  /* aumentado de 40vw para 50vw */
  overflow: hidden;
  position: relative;
  width: 50vw;
  /* aumentado de 40vw para 50vw */
}

.progress-ring__circle {
  height: 70vw;
  /* aumentado de 60vw para 70vw */
  max-height: 72vw;
  /* aumentado de 62vw para 72vw */
  max-width: 72vw;
  /* aumentado de 62vw para 72vw */
  stroke-linecap: round;
  transform: rotate(-90deg);
  transform-origin: 50% 50%;
  transition: 0.35s stroke-dashoffset;
  width: 70vw;
  /* aumentado de 60vw para 70vw */
  z-index: 1;
}

.progress-ring__inner-circle {
  height: 66vw;
  /* aumentado de 56vw para 66vw */
  max-height: 72vw;
  /* aumentado de 62vw para 72vw */
  max-width: 68vw;
  /* aumentado de 58vw para 68vw */
  stroke-linecap: round;
  transform: rotate(-90deg);
  transform-origin: 50% 50%;
  transition: 0.35s stroke-dashoffset;
  width: 66vw;
  /* aumentado de 56vw para 66vw */
  z-index: 2;
}

.circle-image {
  height: 14vw;
  /* aumentado de 11vw para 21vw */
  left: 50%;
  object-fit: fill;
  overflow: visible;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 36vw;
  /* aumentado de 29vw para 39vw */
}

.inner-container {
  /* background: radial-gradient(circle at center, darkgray, lightgray, white); */
  background-color: whitesmoke;
  border-radius: 50%;
  height: 46vw;
  /* aumentado de 36vw para 46vw */
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 46vw;
  /* aumentado de 36vw para 46vw */
}

@media (min-width: 601px) {
  .program-info {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-items: center;
    padding-left: 0vw;
    text-align: left;
    position: absolute;
    top: 56%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: 2000;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
  }

  .circle-image {
    height: 14vw;
    /* aumentado de 11vw para 21vw */
    left: 50%;
    object-fit: fill;
    overflow: visible;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 80vw;
    /* aumentado de 29vw para 39vw */
  }

  .program-info {
    width: auto;
    gap: 1rem;
  }

  .inner-container {
    /* background: radial-gradient(circle at center, darkgray, lightgray, white); */
    background-color: whitesmoke;
    border-radius: 50%;
    height: 46vw;
    /* aumentado de 36vw para 46vw */
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 46vw;
    /* aumentado de 36vw para 46vw */
  }

  .programasImage {
    display: none;
  }
}

@media (min-width: 601px) {
  .circleContainerRow {
    margin-top: 1vw;
    gap: 7rem;
    overflow: auto;
    margin-inline: 1rem;
  }

  .now-playing,
  .program-title {
    font-size: 2vw;
  }

  .program-time {
    font-size: 1.5vw;
    margin-top: 1vw;
    text-align: center;
  }

  .circle-container,
  .progress-ring__circle,
  .progress-ring__inner-circle {
    height: 35vw;
    width: 35vw;
  }

  .circle-image {
    height: 19vw;
    width: 22vw;
  }

  .inner-container {
    height: 30vw;
    width: 30vw;
  }

  .button-container {
    width: 7vw;
    margin: 1vw;
  }
}

.button-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 7vw;
  height: 7vw;
  border-radius: 50%;
  /* background-color: var(--cor-primaria); */

  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}

.play-pause-button {
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  color: var(--cor-primaria);
}

.meuBotao {
  /*background-color: var(--cor-terciaria);
  */
  border: none;
  border-radius: 12px;
  padding: 10px 25px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 1rem;
  margin: 0;
  cursor: pointer;
  -webkit-border-radius: 12px;
  -moz-border-radius: 12px;
  -ms-border-radius: 12px;
  -o-border-radius: 12px;
}

.textoBotao {
  color: var(--cor-terciaria);
  font-size: 24px;
  font-weight: 400;
  font-family: "Rubik", sans-serif;
}

@media (min-width: 600px) {
  .meuBotao {
    position: relative;
    top: 2%;
    width: auto;
    margin-top: 4rem;
    border: 2px soLid var(--cor-primaria);

    /* box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19); */
  }

  .textoBotao {
    color: var(--cor-primaria);
    font-size: 24px;
    font-weight: 400;
    font-family: "Rubik", sans-serif;
  }
}

@media (max-width: 599px) {
  .imgDiscoProgramacao {
    display: none;
    /* Esconde a imagem quando a largura da tela é menor que 600px */
  }
}

.footerDivRow {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: baseline;
  gap: 1vw;
}

@media (max-width: 600px) {
  .footerDivRow {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 0.2rem;
  }

  .meuBotao {
    background-color: whitesmoke;
    border: none;
    border-radius: 12px;
    color: var(--cor-primaria);
    padding: 4px 8px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 12px;
    margin: 2px 1px;
    cursor: pointer;
    align-self: flex-start;
    align-self: center;
    margin-left: 0vw;
    margin-top: 6vw;
    border: 1px solid var(--cor-primaria);
  }

  .inner-container {
    /* background: radial-gradient(circle at center, darkgray, lightgray, white); */
    background-color: whitesmoke;
    border-radius: 50%;
    height: 41vw;
    width: 41vw;
    /* aumentado de 36vw para 42vw */
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);

    /* aumentado de 36vw para 46vw */
  }

  .program-time {
    margin-top: 0vw;
    font-family: "Rubik", sans-serif;
    font-size: 3vw;
    color: var(--cor-primaria);
    font-weight: 200;
    margin-bottom: 2vw;
  }

  .textoBotao {
    color: var(--cor-primaria);
    font-size: 10px;
  }
}

.footerDivRowSpan {
  font-weight: 200;
  font-family: "Rubik", sans-serif;
  color: var(--cor-terciaria);
  font-size: 15px;
  margin-top: 3vw;
}

.verticalLine {
  height: 15px;
  width: 1px;
  background-color: var(--cor-terciaria);

  margin-inline: 3vw;
}

@media (min-width: 601px) {
  .footerDivRowSpan {
    font-weight: 400;
    font-family: "Rubik", sans-serif;
    color: var(--cor-terciaria);
    font-size: 20px;
    margin-top: 3vw;
  }

  .verticalLine {
    height: 30px;
    width: 3px;
    background-color: var(--cor-terciaria);

    margin-inline: 1vw;
  }
}

.live-container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 10px;
}

.live-container span {
  color: white;
}

.live-dot {
  width: 0.4rem;
  height: 0.4rem;
  border-radius: 50%;
  background-color: red;
  margin-right: 5px;
  animation: blink 1s steps(5, start) infinite;

  display: block;
}

@media (min-width: 601px) {
  .live-dot {
    margin-top: 6vw;
    margin-right: 1rem;
    display: none;
  }
}

@keyframes blink {
  to {
    visibility: hidden;
  }
}

.whiteLine6 {
  background-color: var(--cor-terciaria);
  margin-top: 0px;
  margin-bottom: 1.56rem;
  border-radius: 6px;
  height: 6px;
  width: 3%;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  -ms-border-radius: 6px;
  -o-border-radius: 6px;
}

.promoContainerNew {
  width: 100%;
  height: 50rem;

  /*Limitaalarguramáximadocontêiner*/
  position: relative;
  height: 100%;
  padding: 45px;
  margin: 0 auto;
  background-color: #15cbcf;
  /* background: linear-gradient(180deg, #FFC007 0%, #F4E72D 100%); */
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

@media (max-width: 600px) {
  .promoContainerNew {
    width: 100%;

    /*Limitaalarguramáximadocontêiner*/
    position: relative;
    height: 100%;
    padding: 45px;
    margin: 0 auto;
    background: linear-gradient(180deg, #ffc007 0%, #f4e72d 100%);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  .whiteLine6 {
    background-color: var(--cor-terciaria);
    margin-top: 6px;
    margin-bottom: 1.56rem;
    height: 3px;
    width: 6%;
  }

  .live-container {
    display: flex;
    align-items: center;
    justify-content: center;
    /* border: 1px solid white; */
    border-radius: 8px;
    padding: 2px 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
  }

  .live-container span {
    color: white;
    font-size: 8px;
  }

  .live-dot {
    width: 0.6rem;
    height: 0.6rem;
    border-radius: 50%;
    background-color: red;
    margin-right: 0px;
    margin-top: 1.3rem;
    animation: blink 1s steps(5, start) infinite;
  }
}

.promoContainerNew1 {
  width: 100%;
  /*max-width: 1200px;
  */
  /*Limitaalarguramáximadocontêiner*/
  height: auto;
  border-radius: 12px;
  padding: 0px;
  margin: 0 auto;
  -webkit-border-radius: 12px;
  -moz-border-radius: 12px;
  -ms-border-radius: 12px;
  -o-border-radius: 12px;
}

.promoContainerNew1.playing {
  /* position: sticky;
  top: 0; */
  background-color: white;
}

.promoActorRowNew {
  width: 100%;

  border-radius: 12px;
  -webkit-border-radius: 12px;
  -moz-border-radius: 12px;
  -ms-border-radius: 12px;
  -o-border-radius: 12px;
}

@media (max-width: 600px) {
  .promoActorRowNew {
    width: 100%;
    padding-top: 0rem;
    border-radius: 12px;
    -webkit-border-radius: 12px;
    -moz-border-radius: 12px;
    -ms-border-radius: 12px;
    -o-border-radius: 12px;
  }
}

.promoCardNew {
  position: relative;
  width: 100%;
  height: auto;
  max-height: 600px;
}

.promoCardNew img {
  width: 100%;
  object-fit: fill;
  height: 32vw;

  border-radius: 12px;
  -webkit-border-radius: 12px;
  -moz-border-radius: 12px;
  -ms-border-radius: 12px;
  -o-border-radius: 12px;
}

.promoCardNew1 {
  margin-top: 0rem;
  position: relative;
  width: 100%;
  height: auto;
  max-height: 600px;
  transition: transform 0.8s ease;
  transform: scale(1) !important;
  position: relative;
  z-index: 1;
  -webkit-transition: transform 0.8s ease;
  -moz-transition: transform 0.8s ease;
  -ms-transition: transform 0.8s ease;
  -o-transition: transform 0.8s ease;
  -webkit-transform: scale(1) !important;
  -moz-transform: scale(1) !important;
  -ms-transform: scale(1) !important;
  -o-transform: scale(1) !important;
}

.promoContainerNew1.playing {
  transform: none;
  background-color: white;
  margin-top: 0rem;
  -webkit-transform: none;
  -moz-transform: none;
  -ms-transform: none;
  -o-transform: none;
}

.promoCardNew1 img {
  width: 100%;
  object-fit: cover;
  height: 45vw;
  border-radius: 0px;
  -webkit-border-radius: 0px;
  -moz-border-radius: 0px;
  -ms-border-radius: 0px;
  -o-border-radius: 0px;
}

@media (max-width: 600px) {
  .promoCardNew1 img {
    width: 100%;
    object-fit: fill;
    height: 32vw;
    border-radius: 0px;
    -webkit-border-radius: 0px;
    -moz-border-radius: 0px;
    -ms-border-radius: 0px;
    -o-border-radius: 0px;
  }
}

.cartolaAbsoluteNew {
  border-radius: 12px;
  position: absolute;
  top: 0;
  left: 0;
  padding: 10px;
  margin: 10px;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.5);
}

.slick-prev {
  left: -118px !important;
  /* Move a seta para a esquerda */
}

.slick-next {
  right: -40px !important;
  /* Move a seta para a direita */
}

.slick-prev:before,
.slick-next:before {
  font-size: 100px !important;
}

@media (max-width: 600px) {
  .slick-prev {
    left: -40px !important;
    /* Move a seta para a esquerda */
  }

  .slick-next {
    right: -30px !important;
    /* Move a seta para a direita */
  }

  .slick-prev:before,
  .slick-next:before {
    font-size: 30px !important;
    color: white !important;
  }

  .promoContainerNew {
    margin-bottom: 0vw;
  }

  .promoCardNew {
    max-height: 300px;
  }

  .promoCardNew img {
    object-fit: fill;
    height: 50vw;
  }

  .promoCardNew1 {
    max-height: 100%;
    padding-top: 0rem;
    padding-top: 5.5rem;
    transition: padding-top 0.3s ease-in-out;
  }

  .playing.promoCardNew1 {
    max-height: 100%;
    padding-top: 5vw;
    transform: scale(1);
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transition: padding-top 0.3s ease-in-out;
  }

  .promoCardNew1 img {
    object-fit: cover;
    height: 65vw;
  }
}

.pushLine {
  width: 100%;
  height: 0.6vw;
  background-color: white;
  z-index: 50;
  right: 0;
  position: fixed;
  top: 98%;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 1;
  visibility: visible;
  transition: opacity 0.5s, visibility 0.2s;
  -webkit-transition: opacity 0.5s, visibility 0.2s;
  -moz-transition: opacity 0.5s, visibility 0.2s;
  -ms-transition: opacity 0.5s, visibility 0.2s;
  -o-transition: opacity 0.5s, visibility 0.2s;
}

@media (max-width: 600px) {
  .pushLine {
    width: 100%;
    height: 1vw;
    background-color: white;
    z-index: 50;
    right: 0;
    position: fixed;
    top: 98%;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 1;
    visibility: visible;
    transition: opacity 0.5s, visibility 0.2s;
    -webkit-transition: opacity 0.5s, visibility 0.2s;
    -moz-transition: opacity 0.5s, visibility 0.2s;
    -ms-transition: opacity 0.5s, visibility 0.2s;
    -o-transition: opacity 0.5s, visibility 0.2s;
  }
}

.pushLineOpenner {
  display: flex;
  align-items: center;
  justify-content: center;
  width: auto;
  padding-inline: 1.1rem;

  background-color: white;
  position: fixed;

  z-index: 1000;
  padding-block: 0.2vw;
  background-color: white;
  position: fixed;
  bottom: -2.6rem;
  border-radius: 0px 0px 20px 20px;
  -webkit-border-radius: 0px 0px 20px 20px;
  -moz-border-radius: 0px 0px 20px 20px;
  -ms-border-radius: 0px 0px 20px 20px;
  -o-border-radius: 0px 0px 20px 20px;
}

@media (max-width: 600px) {
  .pushLineOpenner {
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    padding-inline: 0.5rem;
    padding-block: 1vw;
    background-color: white;
    position: fixed;
    bottom: -5vw;
    z-index: 1000;
    border-radius: 0px 0px 8px 8px;
    -webkit-border-radius: 0px 0px 8px 8px;
    -moz-border-radius: 0px 0px 8px 8px;
    -ms-border-radius: 0px 0px 8px 8px;
    -o-border-radius: 0px 0px 8px 8px;
  }
}

.playing .pushLine {
  opacity: 0;
  visibility: hidden;
}

.pushLineOpenner span {
  font-size: 1.5rem;
  color: #541084;
  font-weight: 400;
  font-family: "Rubik", sans-serif;
}

.pushLineOpenner svg {
  margin-left: 1vw;
  font-size: 2rem;
  color: #541084;
}

@media (max-width: 600px) {
  .pushLineOpenner span {
    font-size: 0.6rem;
    color: #541084;
    font-weight: 400;
    font-family: "Rubik", sans-serif;
  }

  .pushLineOpenner svg {
    margin-left: 1vw;
    font-size: 0.6rem;
    color: #541084;
  }
}

.imageContainer {
  position: relative;
}

.clickInterceptor {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  /* Cobrir a metade superior da imagem */
  z-index: 1;
  /* Colocar o interceptor acima da imagem */
}

.linkArea {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 50%;
  /* Cobrir a metade inferior da imagem */
  z-index: 1;
  /* Colocar o link acima da imagem */
}

.logoMenuDivRow {
  position: absolute;
  padding-inline: 4rem;
  padding-bottom: 0.4rem;
  width: 100%;
  height: auto;
  top: 0.9%;
  left: 0%;
  z-index: 15;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 49;
  /* background-image: radial-gradient(rgba(0, 0, 0, 0.3), transparent 80%); */
  /* background-color: var(--cor-primaria); */
}

.logoMenuDivRow.playing {
  margin-top: 0vw;
  padding-top: 0vw;
  top: 3rem;
  left: 0;
  position: absolute;
}

@media (max-width: 600px) {
  .logoMenuDivRow {
    background-image: none;
  }

  .logoMenuDivRow.playing {
    margin-top: 0vw;
    padding-top: 0vw;
    top: 1.8rem;
    left: 0;
    position: absolute;
  }
}

@media (max-width: 600px) {
  .logoMenuDivRow.playing {
    padding-top: 12vw;
    justify-content: space-between;
    /* top: 0;
    left: 0; */
  }

  .logoMenuDivRow {
    position: absolute;
    padding-inline: 2rem;
    padding-bottom: 0.4rem;
    width: 100%;
    height: auto;
    top: 0.8%;
    left: 0%;
    z-index: 15;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 0px;
    z-index: 49;
    /* background-color: var(--cor-primaria); */
    -webkit-border-radius: 0px;
    -moz-border-radius: 0px;
    -ms-border-radius: 0px;
    -o-border-radius: 0px;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

.handPointing {
  position: absolute;
  top: -15%;
  left: 40;
  right: 0;
  bottom: 0;
  margin: auto;
  align-self: center;
  font-size: 4rem;

  color: white;
}

.logoMenuDivRow.fixed {
  position: fixed;
  top: 3.6%;
  z-index: 2000;
  background-color: transparent;
  /* top: 0;
  left: 0; */
}

.logoMenuDivRow.playingAndMenuOpen {
  padding-top: 11vw;
  z-index: 2000;
}

.logoMenuDivRow img {
  z-index: 48;
  width: 14rem;
  height: auto;
}

.logoMenuDivRow svg {
  font-size: 3vw;
  color: white;

  stroke: none;
  stroke-miterlimit: 10;
  stroke-width: 1.2px;
}

@media (max-width: 600px) {
  .logoMenuDivRow img {
    z-index: 48;
    width: 9rem;
    height: auto;
  }

  .logoMenuDivRow svg {
    font-size: 6vw;
    color: white;
  }
}

.backgroundDivContainerNews {
  display: flex;
  align-items: center;

  width: 100%;
  /* flex-direction: row; */
  background: linear-gradient(180deg, #541084 0%, #ffc007 100%);
}

.backgroundDivContainerNewsLargeScreen {
  background-color: #541084;
  width: 100%;
  height: 58rem;
  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;
}

.backgroundDivContainerNewsLargeScreenImg {
  position: absolute;
  height: 50rem;
  width: auto;
  object-fit: fill;
  left: 0;
  z-index: 1;
}

.whiteLineLargeScreen {
  background-color: white;
  z-index: 2;
  margin-bottom: 1.56rem;
  height: 5px;
  border-radius: 4px;
  width: 2%;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
  margin-bottom: 3rem !important;
}

.newsContainerLargeScreen {
  display: flex;
  /* width: 100%; */
  flex-direction: row;
  justify-content: space-around;
  padding-inline: 14rem;
  margin-bottom: 4rem;
  z-index: 2;
  gap: 6rem;
}

.newsTitleLargeScreen {
  color: white;
  font-family: "Rubik", sans-serif;
  font-size: 25px !important;
  margin-top: 0px !important;
  height: 6vw;
  font-weight: 400 !important;
  line-height: normal;
  letter-spacing: -0.4px;
  padding-inline: 0vw;
  padding-block: 2rem;
  padding-inline: 2rem;
  text-align: center;
  margin-bottom: 0px !important;
  display: flex;
  align-items: center;
}

.btnStyleLargeScreen1 {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 3rem;
  border: 2px solid white;
  border-radius: 0.3125rem;
  background-color: transparent;
  color: white;
  padding: 1px 30px;
  text-decoration: none;
  font-size: 24px;
  font-weight: 600;
  transition: background-color 0.3s ease;
  -webkit-border-radius: 12px;
  -moz-border-radius: 12px;
  -ms-border-radius: 12px;
  -o-border-radius: 12px;
}

.btnStyleLargeScreen1:hover {
  background-color: white;
  color: #1f1f1d;
}

.newsTitleLargeScreen1 {
  font-size: 25px !important;
  margin-top: 0px !important;
  color: white;
  font-family: "Rubik", sans-serif;

  height: 6vw;
  font-weight: 400 !important;
  line-height: normal;
  letter-spacing: -0.4px;
  padding-inline: 0vw;
  padding-block: 2rem;
  padding-inline: 2rem;
  text-align: center;
  margin-bottom: 0px !important;
  display: flex;
  align-items: center;
}

.btnStyleLargeScreen {
  margin-top: 3rem;
  border: 2px solid white;
  border-radius: 0.3125rem;
  background-color: transparent;
  color: white;
  padding: 3px 30px;
  text-decoration: none;
  font-size: 24px;
  font-weight: 600;
  transition: background-color 0.3s ease;
  -webkit-border-radius: 0.3125rem;
  -moz-border-radius: 0.3125rem;
  -ms-border-radius: 0.3125rem;
  -o-border-radius: 0.3125rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.btnStyleLargeScreen:hover {
  background-color: white;
  color: #541084;
}

.h1StyleDropsLargeScreen {
  padding-top: 0.7rem !important;
  color: white;
  padding-bottom: 10px;
  z-index: 2;
  /* text-shadow: 4px 4px 8px rgba(0, 0, 0, 1); */
}

.newsTitleContainerLargeScreen {
  /* height: 5rem; */
  display: flex;
  align-items: center;
  justify-content: center;
}

.newsItemLargeScreen {
  display: flex;
  flex-direction: column;

  width: 40rem;
  height: auto;
  border-radius: 12px;
  border: 1px solid white;
  -webkit-border-radius: 12px;
  -moz-border-radius: 12px;
  -ms-border-radius: 12px;
  -o-border-radius: 12px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

@media (max-width: 1388px) {
  .newsItemLargeScreen {
    width: 35rem;
  }
}

@media (max-width: 1222px) {
  .newsItemLargeScreen {
    width: 30rem;
  }

  .newsTitleLargeScreen {
    font-size: 20px !important;
    height: 2vw;
  }

  .imgSlider {
    height: 22rem;
  }
}

.newsItemLargeScreen img {
  width: 100%;
  height: 21rem;
  border-radius: 10px;
  object-fit: cover;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

.btnStyleLargeScreen {
  align-self: center;
  /* box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19); */
}

@media (max-width: 600px) {
  .backgroundDivContainerNews {
    display: flex;
    align-items: center;

    width: 100%;
    flex-direction: column;
    background: linear-gradient(180deg, 4 0%, #ffc007 100%);

    z-index: auto;
  }
}

.newsContainer {
  /* display: flex;
  align-items: center;
  width: 150rem; */
  margin-top: 1rem;
  margin-bottom: 1rem;
  border-radius: 12px;
  border: 1px solid white;
  margin-inline: vw;
}

.newsImage {
  width: 75rem;
  height: 42rem;
  border-radius: 12px;
  object-fit: cover;
  -webkit-border-radius: 12px;
  -moz-border-radius: 12px;
  -ms-border-radius: 12px;
  -o-border-radius: 12px;
}

.containerColumnDiv {
  display: flex;
  flex: column;
}

@media (min-width: 600px) {
  .containerColumnDiv {
    flex-direction: row;
  }
}

@media (max-width: 600px) {
  .dividerLine {
    width: 309px;
    height: 49px;
    background-color: white;
    align-self: center;
    margin: 0 auto;
  }

  .newsContainer {
    display: flex;
    width: auto;
    align-items: center;
    margin-top: 1rem;
    margin-bottom: 1rem;
    border-radius: 12px;
    border: 1px solid white;
    margin-inline: 4vw;
  }

  .newsImage {
    width: 50%;
    height: 8rem;
    border-radius: 12px !important;
    object-fit: cover;
    -webkit-border-radius: 12px !important;
    -moz-border-radius: 12px !important;
    -ms-border-radius: 12px !important;
    -o-border-radius: 12px !important;
  }

  .newsContainer {
    /* display: flex;
    align-items: center;
    width: 150rem; */
    margin-top: 1rem;
    margin-bottom: 1rem;
    border-radius: 12px;
    border: 1px solid white;
    margin-inline: 4vw;
  }
}

.newsTitleContainer {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;

  /* Adiciona uma borda fina preta à esquerda do título */
}

.newsTitle {
  margin: 0;
  color: white;
  font-family: "Rubik", sans-serif;
  font-size: 0.9rem;
  text-align: left;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.4px;
  padding-inline: 0vw;
  padding-block: 0.5rem;
  padding-inline: 1rem;
}

@media (max-width: 600px) {
  .newsTitle {
    margin: 0;
    color: white;
    font-family: "Rubik", sans-serif;
    font-size: 0.9rem;
    text-align: left;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.4px;
    padding-inline: 0vw;
    padding-block: 0.5rem;
    padding-inline: 1rem;
  }
}

.fullScreenMenu {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(180deg, #9248ff 0%, #541084 100%);

  /* border-radius: 12px; */
  z-index: 1000;
  transform: translateX(100%);
  transition: transform 0.3s ease-in-out;
  -webkit-transform: translateX(100%);
  -moz-transform: translateX(100%);
  -ms-transform: translateX(100%);
  -o-transform: translateX(100%);
}

.fullScreenMenu.open {
  transform: translateX(0);
}

.menuOpenContainerColumn {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1.2rem;
  padding-top: 10rem;
  transition: none !important;
  transform: none !important;
  -webkit-transition: none !important;
  -moz-transition: none !important;
  -ms-transition: none !important;
  -o-transition: none !important;
  -webkit-transform: scale(1.03);
  -moz-transform: scale(1.03);
  -ms-transform: scale(1.03);
  -o-transform: scale(1.03);
}

.menuOpenContainerColumn h1 {
  color: white;
  text-align: center;
  padding: 0rem !important;
  font-size: 30px !important;
  font-family: Rubik !important;
  letter-spacing: -0.1px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: normal !important;
  padding-top: 3vw !important;
  line-height: 31.6px !important;
  width: 100% !important;
  padding-inline: 0rem !important;
}

.footerSocialMediaContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 1.2rem;
  padding-top: 3rem;
}

.footerSocialMediaContainer svg {
  color: white;
}

.footerSocialMediaContainer img {
  width: 1.2rem;
  height: 1.2rem;
}

.h1StyleDrops {
  color: white;
  text-align: center;
  margin-top: 1rem;
  text-shadow: 4px 4px 8px rgba(0, 0, 0, 1);
}

.whiteLine {
  background-color: white;
  margin-top: 6px;
  margin-bottom: 1.56rem;
  height: 5px;
  border-radius: 8px;
  width: 5%;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;
}

@media (min-width: 600px) {
  .whiteLine {
    width: 2%;
    /* Ajuste este valor para a largura desejada */
  }
}

.btnStylePromo {
  margin-top: 0.3rem;
  margin-bottom: 1.56rem;
  border: 2px solid white;
  border-radius: 0.3125rem;
  background-color: transparent;
  color: white;
  padding: 1px 30px;
  text-decoration: none;
  font-weight: 600;
  font-size: 24px;
  transition: background-color 0.3s ease;
  -webkit-border-radius: 12px;
  -moz-border-radius: 12px;
  -ms-border-radius: 12px;
  -o-border-radius: 12px;
}

.btnStylePromo:hover {
  background-color: #541084;

  color: white;
}

.btnStyle {
  margin-top: 0.3rem;
  margin-bottom: 1.56rem;
  border: 1px solid white;
  border-radius: 0.3125rem;
  background-color: transparent;
  color: white;
  padding: 3px 15px;
  text-decoration: none;
  font-size: 16px;
  transition: background-color 0.3s ease;
  -webkit-border-radius: 12px;
  -moz-border-radius: 12px;
  -ms-border-radius: 12px;
  -o-border-radius: 12px;
}

.btnStyle:hover {
  background-color: white;
  color: black;
}

.placeholderPropraganda {
  margin-top: 1.37rem;
  margin-bottom: 4rem;
  width: 15rem;
  height: 15rem;
  background: transparent;
}

.contentBackground {
  display: flex;
  width: 100%;
  align-items: center;
  flex-direction: column;
  padding-inline: 2rem;

  background: radial-gradient(
      74.24% 70.04% at -20% 56.02%,
      rgba(255, 192, 7, 0.2) 0%,
      rgba(255, 192, 7, 0) 100%
    ),
    radial-gradient(94.87% 155.71% at 141.54% 38.18%, #352598 0%, #1f1f1e 100%);
}

.contentTitle1 {
  color: white;
  text-align: center;
  margin-block: 0.3rem;
  padding-top: 0vw;
}

.foneImg {
  position: absolute;
  top: -17%;
  left: -2%;
  z-index: 555;
  width: auto;
  height: 31vw;
}

.backButton {
  position: absolute;
  left: 0%;
  top: auto;
  border: none;
  background-color: transparent;
  color: white;
  font-size: 1.5rem;
  padding-top: 12vw;
}

.backButton1 {
  position: absolute;
  left: 0%;
  top: auto;
  border: none;
  background-color: transparent;
  color: white;
  font-size: 1.5rem;
  padding-top: 12vw;
}

.topBackContainer {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.h1StyleDrops6 {
  color: var(--cor-terciaria);
  text-align: center;
}

.whiteLine6 {
  background-color: var(--cor-terciaria);
  margin-top: 0px;
  margin-bottom: 1.56rem;
  border-radius: 6px;
  height: 5px;
  width: 5%;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  -ms-border-radius: 6px;
  -o-border-radius: 6px;
}

@media (min-width: 600px) {
  .whiteLine6 {
    background-color: var(--cor-primaria);
    margin-top: 0px;
    margin-bottom: 1.56rem;
    border-radius: 6px;
    height: 5px;
    width: 2%;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;
  }

  .h1StyleDrops6 {
    color: var(--cor-primaria);
    text-align: center;
  }

  .backButton {
    position: absolute;
    left: 0%;
    top: auto;
    border: none;
    background-color: transparent;
    color: white;
    font-size: 3rem;
    padding-top: 4%;

    margin-left: 10rem;
  }

  .topBackContainer {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }

  .contentBackground {
    height: auto;
  }

  .contentTitle1 {
    color: white;
    text-align: center;
    margin-block: 0.3rem;
    margin-bottom: 10px;
  }

  .foneImg {
    position: absolute;
    top: -20%;
    left: 3%;
    z-index: 555;
    width: auto;
    height: 17vw;
  }
}

.contentImage.sidebarVisible {
}

.contentTitle {
  color: white;
  text-align: center;
  margin-top: 1rem;
  z-index: 2;
  pointer-events: none;
}

.dividerLine1 {
  width: 100%;
  height: 49px;
  background-color: transparent;
  align-self: center;
  margin: 0 auto;
  margin-top: 2.2rem;
}

.contentContainer {
  display: flex;
  flex-direction: column;
  gap: 2.34rem;
  margin-bottom: 4rem;
  z-index: 2;
}

.contentItem {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
  width: 100%;
  border: 1px solid white;
  -webkit-border-radius: 12px;
  -moz-border-radius: 12px;
  -ms-border-radius: 12px;
  -o-border-radius: 12px;
  z-index: 2;
}

.flexCenter {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.boxMapPropaganda {
  background-color: transparent;
  width: 20rem;
  height: 3rem;
  margin-top: 8vw;
}

.contentItem.thirdItem {
  border-bottom: 1px solid white;
}

.dividerLine {
  width: 100%;
  height: 49px;
  background-color: transparent;
  align-self: center;
  margin: 0 auto;
  /* margin-bottom: 2.2rem; */
}

.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 2rem;
}

.pagination button {
  background-color: #541084;
  color: white;
  border: none;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  font-family: "Rubik", sans-serif;
}

.pagination1 {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 2rem;
  padding-top: 2rem;
}

.pagination1 button {
  background-color: #9248ff;
  color: white;
  border: none;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  font-family: "Rubik", sans-serif;
}

.pagination1 button:last-child {
  margin-right: 0;
  /* Remove o espaço à direita do último botão */
}

.pagination1 button.activePage {
  background-color: transparent;
  border: 2px solid white;
  /* Altera a cor de fundo para vermelho */
  color: white;
  /* Altera a cor do texto para branco */
}

.pagination2 {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 2rem;
}

.pagination2 button {
  background-color: #22d4d8;
  color: white;
  border: none;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  font-family: "Rubik", sans-serif;
}

.pagination2 button:last-child {
  margin-right: 0;
  /* Remove o espaço à direita do último botão */
}

.pagination2 button.activePage {
  background-color: transparent;
  border: 2px solid white;
  /* Altera a cor de fundo para vermelho */
  color: white;
  /* Altera a cor do texto para branco */
}

.contentBackgroundDrops1 {
  padding-top: 3rem;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-inline: 2rem;
  background: radial-gradient(
      74.24% 70.04% at -20% 56.02%,
      rgba(255, 192, 7, 0.2) 0%,
      rgba(255, 192, 7, 0) 100%
    ),
    radial-gradient(94.87% 155.71% at 141.54% 38.18%, #352598 0%, #1f1f1e 100%);
}

.contentBackgroundDrops2 {
  padding-top: 3rem;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-inline: 2rem;
  /* background: radial-gradient(74.24% 70.04% at -20% 56.02%, rgba(255, 192, 7, 0.20) 0%, rgba(255, 192, 7, 0.00) 100%), radial-gradient(94.87% 155.71% at 141.54% 38.18%, #352598 0%, #1F1F1E 100%); */
  background-color: #22d4d8;
}

.pagination button:last-child {
  margin-right: 0;

  /* Remove o espaço à direita do último botão */
}

.pagination button.activePage {
  background-color: white;
  border: 4px solid #541084;
  /* Altera a cor de fundo para vermelho */
  color: #541084;
  /* Altera a cor do texto para branco */
}

.dummyImage {
  width: 100%;
  height: 200px;
  background-color: #ccc;
  /* Cor de fundo cinza */
  padding-inline: 12vw;
}

.grayImage {
  filter: grayscale(100%);
}

.previousPages {
  /* Estilos para o botão "Anterior" */
  background-color: transparent !important;
  color: #333;
  border: none;
  padding: 10px;
  cursor: pointer;
}

.nextPages {
  /* Estilos para o botão "Próximo" */
  background-color: transparent !important;
  color: #fff;
  border: none;
  padding: 10px;
  cursor: pointer;
}

.backButton3 {
  position: absolute;
  left: 9%;
  top: 5.9rem;
  border: none;
  background-color: transparent;
  color: white;
  font-size: 1.5rem;
}

.fade-in {
  animation: fadeIn ease 2s;
  -webkit-animation: fadeIn ease 2s;
  -moz-animation: fadeIn ease 2s;
  -o-animation: fadeIn ease 2s;
  -ms-animation: fadeIn ease 2s;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-moz-keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-o-keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-ms-keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.loader-container1 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100vh;
  background-color: #541084;
  position: relative;
}

.loader-logo {
  position: absolute;
  height: 40rem;
  width: auto;
  z-index: 1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
}

@media (max-width: 600px) {
  .loader-logo {
    height: 20rem;
  }
}

.loader-bar {
  flex-grow: 1;
  width: 1px;
  height: 20px;
  background-color: white;
  margin: 2.3px;
  border-radius: 3px;
  animation: barAnimation 1s infinite ease-in-out;
  animation-delay: var(--delay, 0s);
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  -o-border-radius: 3px;
  -webkit-animation: barAnimation 1s infinite ease-in-out;
  -webkit-animation-delay: var(--delay, 0s);
}

@keyframes barAnimation {
  0%,
  100% {
    height: 10px;
  }

  50% {
    height: 50px;
  }
}

.loader-bar {
  flex-grow: 1;
  width: 1px;
  height: 20px;
  background-color: white;
  margin: 2.3px;
  border-radius: 3px;
  animation: barAnimation 1s infinite ease-in-out;
  animation-delay: var(--delay, 0s);
}

@keyframes barAnimation {
  0%,
  100% {
    transform: scaleY(0.5);
  }

  50% {
    transform: scaleY(1);
  }
}

@media (min-width: 600px) {
  .loader-bar {
    animation: pulseAnimation 1s infinite ease-in-out;
    animation-delay: var(--delay, 1s);
  }

  @keyframes pulseAnimation {
    0%,
    100% {
      transform: scaleY(0.5);
    }

    50% {
      transform: scaleY(2);
    }
  }

  .pagination2 button.activePage {
    background-color: transparent;
    border: 2px solid #541084;
    color: #541084;
  }

  .pagination2 button {
    background-color: #541084;
    color: white;
    border: none;
    font-size: 1.5rem;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
    font-family: "Rubik", sans-serif;
  }

  .pagination2 button:last-child {
    margin-right: 0;
    color: #541084;
  }

  .contentBackgroundDrops2 {
    padding-top: 0rem;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding-inline: 0rem;
    /* background: radial-gradient(74.24% 70.04% at -20% 56.02%, rgba(255, 192, 7, 0.20) 0%, rgba(255, 192, 7, 0.00) 100%), radial-gradient(94.87% 155.71% at 141.54% 38.18%, #352598 0%, #1F1F1E 100%); */
    background-color: white;
  }

  .pagination button:last-child {
    margin-right: 0;
    color: #541084;

    /* Remove o espaço à direita do último botão */
  }

  .pagination1 button {
    background-color: #541084;
  }

  .pagination1 {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 2rem;
    padding-top: 2rem;
    font-size: 2rem;
  }

  .pagination1 button {
    font-size: 24px;
    width: 50px;
    height: 50px;

    /* Ajuste este valor conforme necessário */
  }

  @keyframes barAnimation {
    0%,
    100% {
      height: 10px;
    }

    50% {
      height: 200px;
    }
  }
}

.whiteLine5 {
  background-color: white;
  margin-top: 0px;
  margin-bottom: 1.56rem;
  height: 5px;
  width: 5%;
  border-radius: 8px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;
}

.contactPage {
}

.promoContainer {
  display: flex;
  flex-wrap: wrap;
}

.relativeContainer {
  position: relative;
}

.relativeImage {
  position: relative;
  width: 50rem;
  height: 30rem;
  border-radius: 12px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.promoContainerNewLarger {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: center;
  margin-bottom: 4rem;
}

.btnStylePromo {
  display: none;
  /* Esconde o botão por padrão */
}

.pagination button {
  background-color: #541084;
  color: white;
  border: none;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  font-family: "Rubik", sans-serif;
}

@media (min-width: 600px) {
  .pagination button {
    background-color: #541084;
    color: white;
    border: none;
    border-radius: 50%;
    font-size: 24px;
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
    font-family: "Rubik", sans-serif;
  }

  .btnStylePromo {
    display: block;
    /* Mostra o botão quando a largura da tela é maior que 600px */
  }

  .whiteLine5 {
    background-color: white;
    margin-top: 0px;
    margin-bottom: 1.56rem;
    height: 5px;
    width: 2%;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
  }
}

.otherImages {
  width: 25rem;
  height: 14.39rem;
  border-radius: 12px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.smallCard1 {
}

.smallCardsContainer {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.otherPromosContainer {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-left: 1.2rem;
  border-radius: 12px;
  -webkit-border-radius: 12px;
  -moz-border-radius: 12px;
  -ms-border-radius: 12px;
  -o-border-radius: 12px;
}

.contentText {
  color: white;
  font-family: "Rubik", sans-serif;
  font-size: 1rem;
  text-align: center;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0px;
  padding: 0.61rem 1.65rem;
  /* Adicione outros estilos para o texto do conteúdo aqui */
}

.mapImg {
  position: absolute;
  top: -7%;
  right: 0;
  z-index: 555;
  width: auto;
  height: 28vw;
}

@media (min-width: 601px) {
  .mapImg {
    position: absolute;
    top: -10%;
    right: 0;
    z-index: 555;
    width: auto;
    height: 22vw;
  }

  .contentText {
    display: flex;
    align-items: center;
    justify-content: center;

    color: white;
    font-family: "Rubik", sans-serif;
    font-size: 1.1rem;
    height: 5rem;
    text-align: center;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0px;
    padding: 0.61rem 1.65rem;
    /* Adicione outros estilos para o texto do conteúdo aqui */
  }

  .contentContainer {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 2rem;
    margin-top: 2rem;
  }

  .contentItem {
    width: 100%;
  }
}

.menuLinks {
  margin-left: 4rem;
  display: flex;
  align-items: center;
  width: auto;
  gap: 4rem;
  justify-content: space-around;
  color: white;
  letter-spacing: 2px;
  font-family: "Rubik", sans-serif;

  font-weight: 400;
  text-shadow: 4px 4px 8px rgba(0, 0, 0, 0.5);
}

.menuLinks a {
  font-size: 1.3rem !important;
}

@keyframes click {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(0.9);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes click {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(0.9);
  }

  100% {
    transform: scale(1);
  }
}

.handPointing {
  /*outraspropriedades*/
  animation: click 2s infinite;
  animation-play-state: paused;
  display: none;
  -webkit-animation: click 1s infinite;
}

.handPointing.clicking {
  animation-play-state: running;
  display: block;
}

.clickInstruction {
  position: absolute;
  top: 44%;
  left: 95%;
  right: 0;
  bottom: 0;

  font-size: 30px;
  align-self: center;
  width: 100%;
  pointer-events: none;
  color: white;
  /* Ajuste este valor conforme necessário */
}

.dividerLine1 {
  display: block;
  /* Esconde o botão por padrão */
}

@media (min-width: 600px) {
  .dividerLine1 {
    display: none;
    /* Mostra o botão quando a largura da tela é maior que 600px */
  }
}

.imgDiscoProgramacao {
  position: absolute;
  top: -11%;
  right: 21%;
}

@media (max-width: 1791px) {
  .imgDiscoProgramacao {
    top: -15%;
    right: 21%;
  }
}

@media (max-width: 1362px) {
  .imgDiscoProgramacao {
    top: -19%;
    right: 11%;
  }
}

.logoMenuDivRow.playing {
  /* top: 10.9%; */
  top: 5%;

  /* Exemplo: altera a cor de fundo para preto */
}

.placeholderPropragandaLargeScreen {
  background-color: transparent;
  height: 2rem !important;
  width: 60%;
  margin-block: 2rem;
  z-index: 2;
}

.circleContainerRow {
  display: flex;
  justify-content: center;
}

.large-screen-element {
  display: none;
}

.cartolaPlusNews {
  position: absolute;
  top: -3.2%;
  align-self: center;
  font-family: "Rubik", sans-serif;
  font-size: 1rem;
  font-weight: 600;

  background-color: white;
  padding: 4px 15px;
  border-radius: 8px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;
}

@media (min-width: 600px) {
  .logoMenuDivRow.playing {
    /* top: 10.9%; */
    top: 14%;

    /* Exemplo: altera a cor de fundo para preto */
  }

  .cartolaPlusNews {
    position: absolute;
    top: -3.2%;
    align-self: center;
    font-family: "Rubik", sans-serif;
    font-size: 1.1rem;
    font-weight: 600;
    font-size: 1.2rem;
    background-color: white;
    padding: 4px 15px;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
  }

  .circleContainerRow {
    display: flex;
    justify-content: space-between;
    /* padding-inline: 4rem; */
    margin-bottom: 12rem;
    padding-left: 4rem;
  }

  .large-screen-element {
    display: block;
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}

.imgSlider {
  width: 100%;
  height: 100%;
  object-fit: cover;
  margin-top: 0rem;
}

@media (min-width: 1396px) {
  .imgSlider {
    height: 42rem;
  }
}

@media (min-width: 1222px) {
  .imgSlider {
    height: 37rem;
  }
}

.plusDegradeImg {
  position: absolute;
  bottom: 42%;
  left: -28%;
  width: 22rem;
  height: auto;
  object-fit: contain;
  z-index: 0;
}

@media (min-width: 601px) {
  .plusDegradeImg {
    position: absolute;
    bottom: 24%;
    left: -15%;
    width: 55rem;
    height: auto;
    object-fit: contain;
    z-index: 1;
  }

  .cartolaPlusNews {
    position: absolute;
    top: -3.2%;
    align-self: center;
    font-family: "Rubik", sans-serif;
    font-size: 1.1rem;
    font-weight: 600;
    font-size: 1.2rem;
    background-color: white;
    color: #1f1f1f;
    padding: 4px 15px;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
  }

  .imgSlider {
    padding-top: 0rem;
    width: 100%;
    height: 94vh;
    object-fit: cover;
  }

  .boxMapPropaganda {
    display: none;
  }

  .MapContainer {
    align-items: center;
    /* background: linear-gradient(180deg, #541084 0%, #9248FF 100%); */
    background-color: #541084;
    display: flex;
    flex-direction: column;
    height: auto;
    justify-content: center;
    padding-bottom: 4vw;
    padding-top: 0vw;
    position: relative;
    width: 100%;
  }
}

@media (min-width: 601px) and (max-width: 1440px) {
  .imgSlider {
    height: 40rem;
  }
}

.footerSocialMediaContainerFooter {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 2.1999999999999993rem;
  padding-top: 3rem;
}

.footerSocialMediaContainerFooter svg {
  color: white;
}

.footerSocialMediaContainerFooter img {
  width: 2.1rem;
  height: 2.1rem;
}

.footerContainerColumnDiv {
  display: flex;
  flex-direction: column;
}

.programacao-titulo.home p {
  font-size: 1.4rem;
  color: white;
  padding-left: 1rem;
  width: 100%;
}

.programacao-row.home {
}

@media (min-width: 601px) {
  .imgSlider {
    padding-top: 0rem;
    width: 100%;
    height: 93vh;
    object-fit: cover;
  }
}
.progress-ring {
  margin: 0 !important;
}

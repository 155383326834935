.containerEsquerda {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-top: 15vw;
    padding-inline: 6vw;
    width: 100%;
    background-color: var(--cor-primaria);
    height: 140vh;
}

@media (min-width: 600px) {
    .containerEsquerda {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
         padding-top: 0vw; 
        padding-inline: 6vw;
        width: 100%;
        background-color: var(--cor-primaria);
        height: 100vh;
    }

}

.lineEditoriais {
    height: 4px;
    width: 55%;
    margin-block: 10px;
    background-color: red;
}

.containerEsquerda h1 {
    color: white;
}

.containerEsquerda h2 {
    margin-block: 1rem;
    color: white;
    font-family: 'Rubik', sans-serif;
}

.containerEsquerda span {

    font-weight: 700;
    color: red;

}

@media (max-width: 600px) {
    .lineEditoriais {
        height: 4px;
        width: 65%;
        border-radius: 2px;
        -webkit-border-radius: 2px;
        -moz-border-radius: 2px;
        -ms-border-radius: 2px;
        -o-border-radius: 2px;
    }

    .containerEsquerda h1 {
        font-size: 1.2rem;
        padding-top: 10vw;

    }

    .containerEsquerda h2 {
        font-size: 0.85rem;
        /* Ajuste para o tamanho desejado */
    }

    .containerEsquerda span {
        font-size: 0.9rem;
        /* Ajuste para o tamanho desejado */
    }
}
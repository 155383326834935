.MenuContainerHeader {
    background-color: var(--cor-primaria) !important;
    height: 8.5rem;
    width: 100%;
    background-color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

@media (max-width: 1420px) {
    .menuLinksDetalhes a {
        font-size: 1.2rem;
    }
}

@media (max-width: 1263px) {
    .menuLinksDetalhes a {
        font-size: 1.1rem;
    }

    .menuLinksDetalhes {
        /* margin-left: 13rem; */
        display: flex;
        align-items: center;
        width: auto;
        gap: 2rem;
        justify-content: flex-start;
        font-size: 1rem;
        color: white;
        letter-spacing: 2px;
        font-family: 'Rubik', sans-serif;
        font-weight: 400;
    }
}

.MenuContainerHeader.playing {
    padding-top: 11.5vw;
}

.logoMenuDivRow.newsPage {
    top: 2%;
    justify-content: flex-start;
}

.logoMenuDivRow.playing.newsPage {
    padding-top: 5.5vw;
    padding-bottom: 1vw;
    top: 0%;
}

:root {
    /* --cor-primaria: #DC143C;
    --cor-primaria-clara: #E26A6E; */
    --tamanho-base-fonte: 16px;
    --cor-primaria: #541084;
    --cor-primaria-clara: #8D1B94;
    --cor-secundaria: #FFC007;
    --cor-terciaria: white;
    --cor-text: black;
    --cor-backgroundClaro: #FFC300;
    --cor-backgroundEscuro: #FF5733;
}



html {
    font-size: var(--tamanho-base-fonte);
}

body {
    font-size: 1rem;
    /* Corpo do texto */
}

h1 {
    font-size: 2rem;
    /* Títulos principais */
}

h2 {
    font-size: 1.5rem;
    /* Subtítulos */
}

h3 {
    font-size: 1.2rem;
    /* Títulos de seção */
}

p {
    font-size: 1rem;
    /* Parágrafos */
}

a {
    font-size: 1rem;
    /* Links */
}

@media (max-width: 600px) {

    .logoMenuDivRow.newsPage {
        top: 2%;
        justify-content: space-between;
    }

    .logoMenuDivRow.playing.newsPage {
        padding-top: 17vw;
        padding-bottom: 1vw;
        top: 0%;
    }

    body,
    h1,
    h2,
    h3,
    p,
    a {
        font-size: 9px;
        /* Exemplo de estilo */
    }
}

.App-headerN {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--cor-primaria);
    padding-top: -0vw;


}

.containerImgLogo {

    margin-right: 0vw;
    margin-left: -1vw;
    position: absolute;
    top: 3.5vw;
    left: 4.7vw;
    z-index: 100;
    width: auto;
    height: 8vw;
    display: none !important;
}

.divMenu {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--cor-terciaria);
    height: 3vw;
    width: 100%;
    position: relative;
}

@media (max-width: 600px) {
    .MenuContainerHeader {
        background-color: var(--cor-primaria) !important;

        height: 6rem;
        width: 100%;
        background-color: white;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .divMenu {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: var(--cor-terciaria);
        height: 9vw;
        width: 100%;
        position: relative;
        margin-top: 15vw;

    }

    .divMenuSpan {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: var(--cor-terciaria);

        width: 100%;
        position: relative;
        text-decoration: none;
        font-size: 9px;
        /* Remove a formatação de link */
        color: inherit;
        padding-block: 1vw;
        /* Faz o link ter a mesma cor do texto ao redor */
    }


}

.divMenuSpan {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--cor-terciaria);
    height: 2vw;
    width: 100%;
    position: relative;
    text-decoration: none;
    /* Remove a formatação de link */
    color: inherit;
    /* Faz o link ter a mesma cor do texto ao redor */
}

.socialIcons {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    right: 5vw;
}

.socialIcons2 {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 2vw;
    right: 5vw;
}

.menuDiv {
    display: flex;
    justify-content: center;
    align-items: center;

}

.App-headerN span {
    color: var(--cor-text);
    font-family: 'Rubik', sans-serif;

    /* Ajuste conforme necessário */
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-inline: 1.5vw;
    font-size: 2.3rem;
}

.App-header {
    display: flex;
    justify-content: center;
    align-items: center;

    padding-top: 4vw;
}




.loader-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-color: var(--cor-primaria);
}

.loader-container p {
    margin-top: 1rem;
    color: white;
}

.ContainerRow.sidebar-open {
    flex-direction: column;
    background: linear-gradient(to bottom, var(--cor-primaria), var(--cor-secundaria) 0%, transparent 100%);
    width: 100%;
    position: absolute;
    top: -5vw;
    width: 100%;
    height: 78vw;
    z-index: 20;
    padding-bottom: vw;

}

.ContainerRow.sidebar-open span {
    color: #FFF;
    width: 100%;


}

.svgTwitter {
    width: 1.5vw;
    height: 1.5vw;

}

.svgTwitter.sidebar-open {
    width: 4.8vw;
    height: 4.8vw;
}

.dropsImage.sidebar-open {
    margin-top: 67vw;
}

.InnerContainerRow {
    display: flex;
    justify-content: center;
    align-items: center;
    width: auto;
    margin-left: 2vw;
    gap: 1vw;
}

.InnerContainerRow.sidebar-open {
    margin-top: 2vw;
    gap: 5vw;
}

/* Esconde os textos quando a tela for pequena */
@media (max-width: 600px) {
    .App-header span {
        display: none;
        margin-inline: 0vw;
    }

    .InnerContainerRow {
        display: none;
    }

    .App-header {
        display: flex;
        padding-top: 2vw;
        transition: padding-top 0.5s ease;
        /* Adiciona transição suave para a propriedade padding-top */
    }

    .playing.App-header {
        display: flex;
        padding-top: 12vw;
    }

    .App-header img {
        width: 30vw;
        height: 15vw;
        margin-right: 44vw;
        object-fit: cover;
    }

    .App-headerN span {
        color: var(--cor-text);
        font-family: 'Rubik', sans-serif;

        /* Ajuste conforme necessário */
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-inline: 1.5vw;
        font-size: 1rem;
    }

}

@media (min-width: 601px) {
    .ButtonMenu {
        display: none;
    }
}

.ButtonMenu {
    background: none;
    border: none;
    padding: 0;
    background-color: var(--cor-primaria);

    width: 2.5rem;
    height: 2.5rem;
    border-bottom-right-radius: 6px;
    border-bottom-left-radius: 6px;
    position: relative;
    top: -3.8vw;
    left: 2vw;
}

.App-header.sidebar-open img {
    width: 30vw;
    height: 15vw;
    margin-right: 0vw;
    object-fit: cover;
    padding: 4vw;
    padding-top: 17vw;
}

.line-container {
    margin-left: auto;

}

.line-container .line {
    height: 2.5px;
    border-radius: 1px;
    background-color: var(--cor-terciaria);
    -webkit-border-radius: 1px;
    -moz-border-radius: 1px;
    -ms-border-radius: 1px;
    -o-border-radius: 1px;
}

.xSvg {
    position: absolute;
    top: 20.5vw;
    right: 12vw;
}

@media (min-width: 600px) {
    .xSvg {
        top: 28vw;
        right: 13vw;
    }
}

.App-header.sidebar-open .InnerContainerRow {
    display: flex;
}

.App-header.sidebar-open {
    display: flex;
    justify-content: center;
    align-items: center;

    padding-top: 4vw;
}

/* Mostra os textos em uma coluna quando o menu lateral estiver aberto */
.App-header.sidebar-open span {
    display: block;

    color: var(--cor-primaria);
    font-family: 'Rubik', sans-serif;

    /* Ajuste conforme necessário */
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-inline: 1.5vw;
    margin-bottom: 6vw;
}

.App-headerN img {
    position: absolute;
    width: 18vw;
    height: auto;
    z-index: 100;
    margin-right: 2vw;
    left: 3vw;
    top: 21.5vw;
    display: none;

}





@media (max-width: 500px) {
    .App-headerN span {
        color: var(--cor-text);
        font-family: 'Rubik', sans-serif;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-inline: 1.5vw;
        font-size: 0.7rem;
    }
}

.ContainerRowN {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.ContainerRowN img {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.ContainerRowN span {
    color: #FFF;
    font-family: 'Rubik', sans-serif;
    /* Ajuste conforme necessário */
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-inline: 1.5vw;
}

.InnerContainerRowN {
    display: flex;
    justify-content: center;
    align-items: center;
    width: auto;
}



.noticiasContainer {
    display: flex;
    flex-direction: column;
    width: 20vw;
    padding-left: calc(11% + (5 * (1640px - 100vw) / 1640));
}

@media (max-width: 1640px) {
    .noticiasContainer {
        width: 20vw;
        padding-left: 11%;
    }
}

.noticiasContainer img {
    border-radius: 10px;
    height: 32rem;
    width: auto;
    margin-bottom: 1rem;
    object-fit: cover;
    /* align-self: center; */
}

h1 {
    color: var(--cor-primaria);

    font-family: Rubik;

    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding-top: 2vw;
    font-size: 40px;
    letter-spacing: -3.08px;
    padding-bottom: 24px;
}

h2 {


    font-family: 'Rubik', sans-serif;
    font-size: 20px;
    letter-spacing: -0.6px;
    margin-block: 0rem !important;
    margin-bottom: 24px !important;
    margin-top: 8px !important;
    font-style: normal;
    font-weight: 200;
    line-height: normal;
    opacity: 0.99;

}

@media (max-width: 600px) {
    .noticiasContainer {

        width: 100%;
        padding-inline: 6vw;
    }

    .noticiasContainer img {
        border-radius: 10px;
        width: 100%;
        /* Substitua pelo valor desejado */
        height: auto;
        margin-bottom: 0.5rem;
    }

    h1 {
        color: var(--cor-primaria);

        font-family: Rubik;

        font-style: normal;
        font-weight: 400;
        line-height: normal;
        padding-top: 2vw;
        font-size: 40px;
        letter-spacing: -3.08px;
        padding-bottom: 0px;
    }

    h2 {


        font-family: 'Rubik', sans-serif;

        letter-spacing: -0.6px;
        margin-block: 0.7vw !important;
        font-style: normal;
        font-weight: 200;
        line-height: normal;
        opacity: 0.99;

    }
}

@media (max-width: 329px) {
    .noticiasContainer {

        width: 100%;

    }
}

h1 {
    color: var(--cor-primaria);

    font-family: 'Rubik', sans-serif;
    padding-bottom: 0px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding-top: 5vw;
    font-size: 40px;
    letter-spacing: -1.08px;

}

h2 {


    font-family: 'Rubik', sans-serif;

    letter-spacing: -0.6px;
    margin-block: 0.7vw;
    font-style: normal;
    font-weight: 200;
    line-height: normal;
    opacity: 0.99;

}

@media (max-width: 600px) {
    h2 {
        font-size: 9px;
        font-size: 18px;
        line-height: 30px;
        letter-spacing: -0.6px;
        padding-inline: 1vw;
        padding-block: 1.5vw;
    }

    h1 {}
}

h3 {
    color: var(--cor-text);

    font-family: 'Rubik', sans-serif;

    font-style: normal;
    font-weight: 500;
    line-height: 1.5px;
    margin-inline: 5.5vw;
}


.descImage {
    color: #2B2B2B;
    font-family: 'Rubik', sans-serif;
    font-size: 1.17rem;
    font-style: normal;
    font-weight: lighter;
    opacity: 0.7;
    font-weight: 200;
    margin-block: 0.2vw;
    margin-left: 0.5vw;
    width: 100%;
}

.meu-conteudo {
    display: flex;
    flex-direction: column;

    margin-bottom: 2vw;
}

.special-strong {
    margin-top: 12px;
    padding-top: 10px;
}

.meu-conteudo a {
    color: var(--cor-primaria);
    font-family: 'Rubik', sans-serif;
    font-size: 1.17rem;
    font-style: normal;
    font-weight: 400;
    line-height: 107.835%;
    text-decoration-line: none;
    text-align: center;
}

.meu-conteudo img {
    width: auto;
    height: 42vw;
    border-radius: 8px;
}

.meu-conteudo a:hover {
    color: var(--cor-primaria);
    text-decoration: none;
}

.meu-conteudo em {
    font-style: italic;
    text-align: right;
}

.meu-conteudo p {
    width: 100%;
    color: #2B2B2B;
    font-family: 'Rubik', sans-serif;
    letter-spacing: -0.6px;
    font-style: normal;
    font-weight: 200;
    line-height: normal;
    padding: 0.5vw;
    font-size: 20px;
    line-height: 2.3rem;
}

.meu-conteudo .alinhado-direita {
    text-align: right;

    font-size: 1.5rem;
}

.social-share-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1vw;
    position: absolute;
    top: 9.4vw;
    left: 8vw;

}

@media (max-width: 600px) {
    .meu-conteudo p {
        font-size: 1rem;
    }

    .meu-conteudo .alinhado-direita {
        text-align: right;
        font-size: 18px;
        color: #2B2B2B;
        font-family: 'Rubik', sans-serif;
    }

    .meu-conteudo p {
        font-size: 20px;
        color: #2B2B2B;
        font-family: 'Rubik', sans-serif;
        letter-spacing: -0.6px;
        font-style: normal;
        font-weight: 200;
        line-height: normal;
        padding: 0.5vw;
        line-height: 30px;
        letter-spacing: -0.6px;
    }

    .social-share-container {
        display: none;
    }
}

/* Adicione a classe .alinhado-direita ao elemento em que deseja alinhar à direita */


.meu-conteudo strong {

    font-family: 'Rubik', sans-serif;


    font-style: normal;
    font-weight: 400;
    line-height: 107.835%;
    text-align: center;
}

.has-text-align-center {
    text-align: center;
    margin-block: 0.5vw;
}



.social-share-container.fixed {
    position: fixed;
    top: 8vw;
    left: 8vw;
}

.final-position {
    position: absolute;
    top: 80vw;
    /* ajuste para a posição final desejada */
}

.spotify-iframe {
    height: 12vw;
    border-radius: 8px;
    margin-block: 2vw;
}

.twitter-iframe {
    height: 30vw;
    border-radius: 8px;
    margin-block: 3vw;
}

.youtube-iframe {
    height: 30vw;
    border-radius: 8px;
    margin-block: 3vw;
}

.twitter-tweet {
    max-width: 100% !important;
    height: 300px !important;
}

.iconDesc {
    weight: 6vw;
    font-size: 1.1 rem;
    color: var(--cor-primaria);
}

.meu-conteudo img {
    width:48vw;
    height: 32vw;
    border-radius: 8px;
}

@media (max-width: 600px) {
    .meu-conteudo img {
        width: 100%;
        height: 42vw;
        border-radius: 8px;
    }

    .iconDesc {
        font-size: 0.8rem;
        color: var(--cor-primaria);
    }

    .meu-conteudo a {
        color: var(--cor-primaria);
        font-family: 'Rubik', sans-serif;
        font-style: normal;
        font-weight: 400;
        line-height: 107.835%;
        text-decoration-line: none;
        text-align: center;
        font-size: 18px;
    }
}

@media (max-width: 600px) {
    .youtube-iframe {
        height: 40vw;
    }

    .spotify-iframe {
        height: 20vw;
    }

    h1 {
        color: var(--cor-primaria);
        font-size: 35px;
        font-family: Rubik;
        letter-spacing: -1.08px;
        font-style: normal;
        font-weight: 400;

        padding-top: 3vw;
        line-height: 32.6px;
        width: 100%;
        padding-inline: 0.2rem;
    }

    .descImage {
        color: #2B2B2B;
        font-family: 'Rubik', sans-serif;
        font-size: 0.62rem;
        font-style: normal;
        font-weight: lighter;
        opacity: 0.7;
        font-weight: 200;
        margin-block: 0.1vw;
        margin-left: 1vw;
    }
}

@media (max-width: 466px) {
    h1 {
        color: var(--cor-primaria);
        font-size: 30px;
        font-family: Rubik;
        letter-spacing: -0.1px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        /* padding-top: 6vw; */
        line-height: 31.6px;
        width: 100%;
        padding-inline: 0rem;

    }
}

.containerDivisaoP {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 5vw;
    width: 29vw;
    margin-top: 2vw;
    background-color: var(--cor-primaria);
    border-radius: 5px;
    padding-inline: 1vw;
    margin-left: 5vw;

    color: #FFF;

    font-family: 'Rubik', sans-serif;


    font-style: normal;
    font-weight: 700;
    margin-bottom: 7vw;
}



.containerDivisao {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 3vw;
    width: 16vw;
    margin-top: 5vw;
    background-color: var(--cor-primaria);
    border-radius: 8px;
    padding-inline: 1vw;
    margin-left: 4.1vw;
    font-size: 2.2rem;
    color: #FFF;

    font-family: 'Rubik', sans-serif;


    font-style: normal;
    font-weight: 200;
    margin-bottom: 2vw;
}

.containerDivisaoC {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 2.5vw;
    width: 28vw;
    margin-top: 2vw;
    background-color: var(--cor-primaria);
    border-radius: 8px;
    padding-inline: 1vw;
    margin: auto;
    font-size: 2rem;
    color: #FFF;

    font-family: 'Rubik', sans-serif;
    font-style: normal;
    font-weight: 200;
    margin-bottom: 3vw;
}


@media (max-width: 1758px) {
    .containerDivisaoC {

        width: 28vw;

    }
}

@media (max-width: 1404px) {
    .containerDivisaoC {

        width: 32vw;

    }
}

@media (max-width: 600px) {
    .containerDivisaoC {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 3.5vw;
        width: 37vw;
        margin-top: 2vw;
        background-color: var(--cor-primaria);
        border-radius: 8px;
        padding-inline: 1vw;
        margin-left: 4vw;
        font-size: 3rem;
        color: #FFF;

        font-family: 'Rubik', sans-serif;


        font-style: normal;
        font-weight: 200;
        margin-bottom: 3vw;
    }

    .containerDivisao {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 5vw;
        width: 27vw;
        margin-top: 5vw;
        background-color: var(--cor-primaria);
        border-radius: 8px;
        padding-inline: 1vw;
        margin-left: 6.1vw;
        color: #FFF;
        font-family: 'Rubik', sans-serif;
        font-style: normal;
        font-weight: 200;
        margin-bottom: 2vw;
        -webkit-border-radius: 8px;
        -moz-border-radius: 8px;
        -ms-border-radius: 8px;
        -o-border-radius: 8px;
        font-size: 0.9rem;
    }

    .containerDivisaoC {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 5vw;
        width: 100%;
        margin-top: 2vw;
        background-color: var(--cor-primaria);
        border-radius: 0px;
        padding-inline: 1vw;
        /*margin-left: 6vw;
        */
        margin: 0;
        color: #FFF;
        font-family: 'Rubik', sans-serif;
        font-style: normal;
        font-weight: 200;
        margin-bottom: 3vw;
        font-size: 0.9rem;
        -webkit-border-radius: 0px;
        -moz-border-radius: 0px;
        -ms-border-radius: 0px;
        -o-border-radius: 0px;
    }
}


.containerDivisaoR {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 5vw;
    width: 44vw;
    margin-top: 5vw;
    background-color: var(--cor-primaria);
    border-radius: 24px;
    padding-inline: 1vw;
    margin-left: 5vw;

    color: #FFF;

    font-family: 'Rubik', sans-serif;

    font-style: normal;
    font-weight: 700;
}


.maisNoticias {
    display: flex;
    flex-direction: column;
    justify-content: space-between;


}

.maisNoticias img {
    border-radius: 8px;
}


.maisNoticiasR {
    display: flex;
    flex-direction: column;
    /* width: 61rem !important; */
    margin-top: 20px;
    gap: 1rem;
    align-items: center;
    /* padding-left: 18.4rem;
    padding-right: 28rem; */
}



.noticia {
    display: flex;
    width: 62vw;

    padding: 10px;
    box-sizing: border-box;
    margin-left: 3.5vw;
}

.noticia img {
    width: 16vw;
    height: 12vw;
    object-fit: cover;
    margin-right: 10px;
}

.noticia div {
    display: flex;
    flex-direction: column;
    justify-content: center;


}

.noticia h4 {
    padding-left: 1vw;
    margin: 1vw 0;
    font-family: 'Rubik', sans-serif;
    font-size: 2rem;
    color: var(--cor-primaria);
    font-style: normal;
    font-weight: 400;
}

.noticia h5 {
    padding-left: 1vw;
    margin: 1vw 0;
    font-size: 1.6rem;
    font-weight: 200;


    font-style: normal;

}

@media (max-width: 600px) {
    .maisNoticiasR {
        display: flex;
        flex-direction: column;
        /* width: 61rem !important; */
        margin-top: 20px;
        gap: 2rem;
        align-items: center;
        padding: 0rem;
    }

    .maisNoticiasR {
        display: flex;
        flex-direction: column;
        width: auto !important;
        margin-top: 20px;
        gap: 0rem;
    }

    .noticia {
        display: flex;
        width: 80vw;

        padding: 10px;
        box-sizing: border-box;
        margin-left: 3.5vw;
    }

    .noticia h4 {
        /* Insira seus estilos aqui */
        font-size: 13px;
        /* Exemplo de estilo */
    }

    .noticia h5 {
        /* Insira seus estilos aqui */
        margin: 0.1vw 0;
        font-size: 11px;
        /* Exemplo de estilo */
    }
}

.noticia a {
    color: inherit;
    text-decoration: none;
}

.containerColuna1 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 21.5rem;
    margin-left: 11.8125rem;
}

@media (max-width: 1284px) {
    .containerColuna1 {

        margin-left: 8.8125rem;
    }
}

.containerSpanFooter h5 {

    margin: 0 0;
    font-family: 'Rubik', sans-serif;
    font-size: 15px;

    font-style: normal;
    font-weight: 200;
    width: 75%;
    height: 4vw;
    opacity: 0.8;
    margin-top: 0.5vw;
    margin-bottom: 1rem;
}

.containerColuna2 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 21.5rem;
}

.containerColuna1 img,
.containerColuna2 img,
.containerColuna3 img {
    width: 21.5rem;
    height: 12rem;
    object-fit: cover;
    border-radius: 10px;
    margin-bottom: 0.5vw;
}

@media (max-width: 600px) {
    .containerColuna2 {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 85%;
    }

    .containerSpanFooter h5 {

        margin: 0 0;
        font-family: 'Rubik', sans-serif;
        font-size: 1.6rem;

        font-style: normal;
        font-weight: 200;
        width: 85%;
        height: 4vw;
        opacity: 0.8;
        margin-top: 0.5vw;
        margin-bottom: 4vw;
    }

    .containerColuna1 {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 85%;
        margin-left: 4vw;
    }

    .containerColuna1 {
        margin-left: 5.78vw;
    }

    .noticia img {
        width: 36vw;
        height: 24vw;
        object-fit: cover;
        margin-right: 10px;
    }

    .containerColuna1 img,
    .containerColuna2 img,
    .containerColuna2 img {
        width: 23vw;
        height: 12vw;
        object-fit: cover;
        border-radius: 10px;
        margin-bottom: 0.5vw;
    }
}



.containerColuna3 {
    display: flex;
    flex-direction: column;
    justify-content: center;

}

.containerSpanFooter {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.containerSpanFooter h4 {

    margin: 0 0;
    font-family: 'Rubik', sans-serif;
    font-size: 18px;
    color: var(--cor-primaria);
    font-style: normal;
    font-weight: 400;
    width: 85%;
}







.App-headerImg3 {
    margin-right: 0vw;
    margin-left: -1vw;
    position: fixed;
    top: 3.5vw;
    left: 4.7vw;
    z-index: 115;
    width: auto;
    height: 8vw;
    display: none;

}

@media (max-width: 1439px) {
    .App-headerImg3 {
        display: none;
    }
}

.App-header img:first-child {
    width: 22vw;
    height: 11vw;

    margin-right: 5vw;
    margin-left: 7vw;
    left: 4vw;
    top: 0vw;

}

.noticiaDetalheDiv {
    background-color: white !important;
}

.meu-conteudo blockquote {
    /* Adicione suas regras de estilo aqui */
    border-left: 5px solid #ccc;
    padding-left: 20px;
    color: #666;
    display: none;
}

.tweet-container {
    width: 100%;



}







.twitterLogo {
    width: 1.5vw !important;
    height: 1.5vw !important;
    z-index: 22;
    margin-right: 0vw !important;
    margin-left: 0vw !important;
    margin-bottom: 0.3vw;
}

@media (max-width: 600px) {
    .App-header img:first-child {
        width: 32vw;
        height: 16vw;
        z-index: 22;
        margin-right: 42vw;
        margin-left: 4vw;
        left: 1vw;
        top: -4vw;
        position: relative;
    }

    .containerColuna1 img,
    .containerColuna2 img,
    .containerColuna3 img {
        width: 42vw;
        height: 22vw;
        object-fit: cover;
        border-radius: 10px;
        margin-bottom: 0.5vw;
    }

    .containerSpanFooter h4 {

        margin: 0 0;
        font-family: 'Rubik', sans-serif;
        font-size: 12px;
        color: var(--cor-primaria);
        font-style: normal;
        font-weight: 400;
        width: 85%;
    }

    .containerSpanFooter h5 {

        margin: 0 0;
        font-family: 'Rubik', sans-serif;

        font-size: 12px;
        font-style: normal;
        font-weight: 200;
        width: 85%;
        height: 4vw;
        opacity: 0.8;
        margin-top: 0.5vw;
        margin-bottom: 12vw;
    }
}

.logoMenuDivRow svg.newsPageIcon {
    color: #541084 !important;
    /* substitua por sua cor desejada */
}

@media (max-width: 600px) {
    .logoMenuDivRow svg.newsPageIcon {
        color: #541084 !important;
        /* substitua por sua cor desejada */
    }

    .containerSpanFooter h4 {

        margin: 0 0;
        font-family: 'Rubik', sans-serif;
        font-size: 12px;
        color: var(--cor-primaria);
        font-style: normal;
        font-weight: 400;
        width: 85%;
    }

    .containerSpanFooter h5 {

        margin: 0 0;
        font-family: 'Rubik', sans-serif;
        letter-spacing: -0.4px;
        font-size: 11px;
        font-style: normal;
        font-weight: 200;
        width: 85%;
        height: 4vw;
        opacity: 0.8;
        margin-top: 0.5vw;
        margin-bottom: 12vw;
    }
}

.newsPageIcon {
    color: #541084 !important;
    /* substitua por sua cor desejada */
}



svg.newsPageIcon {
    color: #541084 !important;
}

.noticiasContainer.playing {
    padding-top: 4rem;
    /* ajuste para o valor desejado */
}



.icon-row {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #541084;
    padding-block: 0.5rem;
    gap: 1rem;
}

.icon-row img {
    width: auto;
    height: 1.8rem;

}

@media (min-width: 600px) {
    .div-bloco-cinza {
        display: none;
        /* Esconde a div quando a largura da tela é 600px ou mais */
    }
}

.containerColuna3 {
    display: none;
    /* Esconde a coluna por padrão */
}

@media (min-width: 600px) {
    .containerColuna3 {
        display: block;
        /* Mostra a coluna quando a largura da tela é 600px ou mais */
        width: 21.5rem;
    }
}





.bloco-cinza {
    display: none;
    /* Esconde os blocos por padrão */
    width: 64%;

    height: 39rem;
}

.bloco-cinza-grande {
    display: none;
    width: 64%;
    /* Largura do bloco */
    height: 39rem;
    /* Esconde os blocos por padrão */
}

.container-blocos {

    flex: 1;
    /* Adicione esta linha */
    width: 100%;

    display: none;
    flex-direction: column;
}

@media (min-width: 600px) {
    .container-blocos {
        display: block;
        flex: 1;
        /* Adicione esta linha */
        width: 100%;


        display: flex;
        flex-direction: column;
    }

    .bloco-cinza-grande {
        display: block;
        background-color: transparent;
        /* Cor cinza */
        border-radius: 10px;
        margin-top: 3rem;
        margin-inline: auto;
        /* Bordas arredondadas */
        width: 64%;
        /* Largura do bloco */
        height: 39rem;
        /* Altura do bloco */
        margin-bottom: 6rem;
        position: relative;
        /* Espaço entre os blocos */
    }

    .bloco-cinza {
        display: block;
        background-color: transparent;
        /* Cor cinza */
        border-radius: 10px;
        /* Bordas arredondadas */
        width: 64%;
        margin-inline: auto;
        /* Largura do bloco */
        height: 16rem;
        /* Altura do bloco */
        margin-bottom: 10px;
        /* Espaço entre os blocos */
        position: relative;

    }
}

.containerPrincipal {
    display: flex;
    /* Define o layout como Flexbox */
}

.noticiasContainer {
    flex-grow: 1;
    /* Faz o noticiasContainer ocupar todo o espaço disponível */
}

.bloco-cinza-grande,
.bloco-cinza {
    /* Estilos dos blocos cinzas */
}

.menuLinksDetalhes {
    margin-left: 4rem;
    display: none;
    align-items: center;
    width: auto;
    gap: 4rem;
    justify-content: center;
    color: #FFC007;
    letter-spacing: 2px;
    font-family: 'Rubik', sans-serif;

    font-weight: 400;

    /* Adiciona um fundo preto semi-transparente */
}

.menuLinksDetalhes a {
    font-size: 1.3rem;
}

.pagination1 button {
    background-color: #541084;
    color: white;
    border: none;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
    font-family: 'Rubik', sans-serif;
}

.pagination1 button:last-child {
    margin-right: 0;


    /* Remove o espaço à direita do último botão */
}

.pagination1 button.activePage {
    background-color: white;
    border: 2px solid #541084;
    /* Altera a cor de fundo para vermelho */
    color: #541084;
    /* Altera a cor do texto para branco */
}

@media (min-width: 600px) {
    .pagination1 button:last-child {
        margin-right: 0;

        color: #541084;
        /* Remove o espaço à direita do último botão */
    }


    .menuLinksDetalhes {
        /* margin-left: 13rem; */
        display: flex;
        align-items: center;
        width: auto;
        gap: 4rem;
        justify-content: flex-start;
        font-size: 1rem;
        color: white;
        letter-spacing: 2px;
        font-family: 'Rubik', sans-serif;
        font-weight: 400;
    }

    .menuLinksDetalhes a {
        font-size: 1.3rem;
        text-shadow: 4px 4px 8px rgba(0, 0, 0, 1);
    }

    .logoMenuDivRow.newsPage {
        top: 2%;
        justify-content: center;
        /* margin-left: 3.5rem; */
        /* Alinha o conteúdo à esquerda */
    }
}



.footerImage5 {
    height: auto;
    margin: 0px;
    object-fit: cover;
    width: 10rem;
    align-self: flex-end;
}

.footerText1 {
    color: #ffffff;
    font-family: 'Rubik', sans-serif;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 200;
    line-height: normal;
    margin-top: 2vw;
    margin-bottom: 10px;

    z-index: 5;
    text-align: right;
}

@media (max-width: 600px) {
    .footerText1 {
        color: #ffffff;
        font-family: 'Rubik', sans-serif;
        font-size: 13px;
        font-style: normal;
        font-weight: 200;
        line-height: normal;
        margin-block: 1vw;
        z-index: 5;
    }
}

.adsense-container {
    display: none;
}

@media (min-width: 1440px) {
    .adsense-container {
        display: block;
    }
}

.adsense-container-block {
    visibility: hidden;
}

@media (min-width: 600px) {
    .adsense-container-block {
        visibility: visible;
    }
}
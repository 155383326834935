body {
  overflow: auto;
}

.cor-de-fundo-especial {
  background-color: var(--cor-primaria);
}

.programacao-container {
  display: flex;
  overflow: visible !important;
  background-color: var(--cor-primaria);
  height: auto;
  flex-direction: column;
  padding-block: 1rem;
  gap: 0.8rem;
  padding-inline: 1rem;
  width: 100%;
}

.botoes-dia-semana {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-bottom: 1rem;
  margin-top: 2rem;
}
@media (max-width: 600px) {
  .botoes-dia-semana {
    margin-top: 2rem;
  }
}
.placeholderPropragandaLargeScreen {
  background-color: transparent;
  height: 2rem !important;
  width: 60%;
  margin-block: 2rem;
  z-index: 2;
}
.placeholderPropragandaLargeScreenProg {
  background-color: transparent;
  height: 2rem !important;
  width: 60%;
  margin-bottom: 0rem;
  z-index: 2;
  align-self: center;
}

.placeholderPropragandaLargeScreenProg:first-of-type {
  margin-top: 8rem;
}
.botao-dia {
  align-items: center;
  justify-content: center;
  text-align: justify;
  font-family: "Rubik", sans-serif;
  background-color: #9248ff;
  color: white !important;
  border-radius: 12px;
  padding: 10px 11px;
  border: none;
  transition: all 0.5s ease;
}

.select-container {
  position: relative;
  display: inline-block;
}

.botao-dia {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}

select option:disabled {
  color: white;
}

.backButton7 {
  position: absolute;
  left: 5%;
  top: auto;
  border: none;
  background-color: transparent;
  color: white;
  font-size: 5rem;
  padding-top: 7vw;
}

.programacao-row {
  display: flex;
  width: 90%;
  justify-content: space-between;
  align-items: center;
  /* Aqui */
  border-radius: 12px;
  background-color: #9248ff;
  -webkit-border-radius: 12px;
  -moz-border-radius: 12px;
  -ms-border-radius: 12px;
  -o-border-radius: 12px;
}

.programacao-expanded-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  background-color: #ffc007;
  position: relative;
  width: 90%;

  border-radius: 12px;
  -webkit-border-radius: 12px;
  -moz-border-radius: 12px;
  -ms-border-radius: 12px;
  -o-border-radius: 12px;
}

.programacao-expanded-titulo {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  border-radius: 12px;
  padding-top: 1rem;
  background-color: #ffc007;
  line-height: normal;
  margin-left: 0.2rem;
}

.programacao-expanded-titulo h1 {
  padding: 0rem;
  letter-spacing: 0px;
  line-height: normal;
}

.contentBackgroundDrops1 {
  padding-top: 0rem;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-inline: 0rem;
  background: transparent;
}

@media (max-width: 600px) {
  .backButton7 {
    position: absolute;
    left: 5%;
    top: auto;
    border: none;
    background-color: transparent;
    color: white;
    font-size: 5rem;
    padding-top: 8vw;
  }

  .botao-dia {
    align-items: center;
    justify-content: center;
    text-align: center;
    font-family: "Rubik", sans-serif;
    background-color: #9248ff;
    color: white !important;
    border-radius: 12px;
    padding: 10px 10px;

    border: none;
    transition: all 0.5s ease;
  }

  .programacao-expanded-titulo {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    padding-top: 0.4rem;
    background-color: #ffc007;
    line-height: normal;
    margin-left: 0.2rem;
  }

  .pagination1 button {
    background-color: #1f1f1e;
    color: white;
    border: none;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
    font-family: "Rubik", sans-serif;
  }

  .pagination1 button:last-child {
    margin-right: 0;
    /* Remove o espaço à direita do último botão */
  }

  .pagination1 button.activePage {
    background-color: transparent;
    border: 2px solid white;
    /* Altera a cor de fundo para vermelho */
    color: white;
    /* Altera a cor do texto para branco */
  }

  .contentBackgroundDrops1 {
    padding-top: 3rem;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding-inline: 2rem;
    background: radial-gradient(
        74.24% 70.04% at -20% 56.02%,
        rgba(255, 192, 7, 0.2) 0%,
        rgba(255, 192, 7, 0) 100%
      ),
      radial-gradient(
        94.87% 155.71% at 141.54% 38.18%,
        #352598 0%,
        #1f1f1e 100%
      );
  }

  .programacao-expanded-titulo {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    background-color: #ffc007;
    line-height: normal;
    /* padding-top: 1rem; */
    /* margin-left: 1rem; */
  }

  .programacao-expanded-titulo {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding-inline: 1rem;
    border-radius: 12px;
    background-color: #ffc007;
    line-height: normal;
    /*margin-left: 0.2rem;
        */

    -webkit-border-radius: 12px;
    -moz-border-radius: 12px;
    -ms-border-radius: 12px;
    -o-border-radius: 12px;
  }

  .programacao-expanded-titulo h1 {
    padding: 0rem;
    letter-spacing: 0px;
    line-height: normal;
  }

  .programacao-row {
    display: flex;
    width: 100%;
    justify-content: space-between;
    border-radius: 12px;
    background-color: #9248ff;
    -webkit-border-radius: 12px;
    -moz-border-radius: 12px;
    -ms-border-radius: 12px;
    -o-border-radius: 12px;
  }

  .backButton7 {
    position: absolute;
    left: 3%;
    top: auto;
    border: none;
    background-color: transparent;
    color: white;
    font-size: 1.5rem;
    padding-top: 14vw;
  }

  .programacao-container {
    display: flex;
    overflow: visible !important;
    background-color: var(--cor-primaria);
    height: auto;
    flex-direction: column;
    padding-top: 15vw;
    gap: 0.8rem;
    padding-inline: 1rem;
  }

  .programacao-expanded-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    background-color: #ffc007;
    position: relative;
    width: 100%;

    border-radius: 12px;
    -webkit-border-radius: 12px;
    -moz-border-radius: 12px;
    -ms-border-radius: 12px;
    -o-border-radius: 12px;
  }
}

.whiteLine8 {
  background-color: white;
  margin-top: 6px;
  margin-bottom: 1.56rem;
  height: 3px;
  width: 6%;
  align-self: center;
}

.programacao-row:last-child {
}

.programacao-row.current {
  background-color: #f0f0f0;
  /* Ajuste a cor de fundo conforme necessário */
}

.whiteLine4 {
  height: 3px;
  border-radius: 1px;
  background-color: white;
  width: 1.5rem;
  align-self: center;
  margin-top: -0.3rem;
  margin-bottom: 1vw;
  -webkit-border-radius: 1px;
  -moz-border-radius: 1px;
  -ms-border-radius: 1px;
  -o-border-radius: 1px;
}

.propagandaDiv {
  width: 90%;
  align-self: center;
  height: 3.5rem;
  margin-block: 1rem;
}

.programacao-titulo {
  width: 100%;
  padding-block: 1rem;
  display: flex;
  align-items: center;

  font-family: "Rubik", sans-serif;
}

.programacao-titulo p {
  font-size: 0.9rem;
  color: white;
  padding-left: 1rem;
}

.programacao-data {
  width: 6rem;
  padding-inline: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-family: "Rubik", sans-serif;
}

.programacao-data p {
  font-size: 1.2rem;
  font-family: "Rubik", sans-serif;
  color: white;
}

.programacao-expand {
  width: auto;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  cursor: pointer;
  padding-right: 1rem;
}

.programacao-expand svg {
  color: white;
  font-size: 1.2rem;
}

.programacao-expanded-row.expanded {
  background-color: #b786ff;
  border-radius: 0px 0px 12px 12px;
  -webkit-border-radius: 0px 0px 12px 12px;
  -moz-border-radius: 0px 0px 12px 12px;
  -ms-border-radius: 0px 0px 12px 12px;
  -o-border-radius: 0px 0px 12px 12px;

  position: relative;
  bottom: 0.6rem;
  z-index: 1;
  margin-bottom: -0.6rem;
}

.programacao-expanded-row.expanded .programacao-expanded-titulo {
  background-color: #b786ff;
  border-radius: 12px;
  color: white;
}

.programacao-expanded-row.expanded .programacao-expanded-titulo h1 {
  background-color: #b786ff;
  color: white;
  margin-top: 0.9rem;
}

.programacao-expanded-row.expanded .programacao-expanded-titulo span {
  background-color: #b786ff;
}

.programacao-expanded-row.expanded .programacao-expanded-titulo p {
  background-color: #b786ff;
}

.programacao-expanded-row.expanded .programacao-imagem {
  background-color: #b786ff;
  border-radius: 0px 12px 12px 12px;
  -webkit-border-radius: 0px 12px 12px 12px;
  -moz-border-radius: 0px 12px 12px 12px;
  -ms-border-radius: 0px 12px 12px 12px;
  -o-border-radius: 0px 12px 12px 12px;
}

.programacao-expanded-row p {
  background-color: #ffc007;
}

.programacao-expanded-row h1 {
  font-size: 1.1rem;
  background-color: #ffc007;
}

.programacao-imagem {
  width: 100px;
  height: 100px;
  background-color: #ffc007;

  border-radius: 12px;
  -webkit-border-radius: 12px;
  -moz-border-radius: 12px;
  -ms-border-radius: 12px;
  -o-border-radius: 12px;
  display: flex;
  flex-direction: column;
  /* Alinha os itens em coluna */
  align-items: center;
  justify-content: center;
}

.programacao-imagem p {
  font-family: "Rubik", sans-serif;
  color: var(--cor-primaria);
  font-weight: 400;
  line-height: normal;
  font-size: 1.4rem;
}

.programacao-imagem img {
  object-fit: contain;
  height: 2rem;
  width: 4rem;
}

.programacao-imagem .larger-image {
  object-fit: contain;
  height: auto;
  width: 4rem;
}

.programacao-expanded-titulo span {
  font-family: "Rubik", sans-serif;
  background-color: #ffc007;
  font-weight: 200;
  line-height: normal;
}

.programacao-expanded-titulo p {
  padding: 0;
  margin-block: 1rem;
  font-family: "Rubik", sans-serif;
  line-height: normal;
}

.bottom-aligned {
  align-self: flex-end;
  /* Alinha o conteúdo à parte inferior */
}

.current-program {
  /* Adicione suas modificações aqui */
}

.last-expanded {
  margin-bottom: 100vh;
  /* ou qualquer valor que você deseja */
}

.special-program {
}

.special-program img {
  width: 6rem !important;
  height: 6rem !important;
}

.ceara-news-image {
  width: 5rem !important;
  height: 5rem !important;
}

.special-program img:hover {
  transform: scale(1.1);
}

.special-program-image {
}

.backButton1 {
  position: absolute;
  left: 6%;
  top: auto;
  border: none;
  background-color: transparent;
  color: white;
  font-size: 1.5rem;
  padding-top: 12vw;
}

.programa-enter {
  opacity: 0;
  transform: scale(0.9);
}

.programacao-lista {
  display: block;
}

.programa-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 500ms, transform 500ms;
}

.programa-exit {
  opacity: 1;
}

.programa-exit-active {
  opacity: 0;
  transform: scale(0.9);
  transition: opacity 500ms, transform 500ms;
}

.program-transition {
  transition: opacity 0.5s ease-in-out;
}

.programacao-expanded-row.home {
  background-color: #541084;
}

.programacao-imagem.home {
  background-color: #541084;
}

.programacao-expanded-titulo.home {
  background-color: #541084;
}

.programacao-expanded-titulo.home h1,
.programacao-expanded-titulo.home span,
.programacao-expanded-titulo.home p {
  background-color: #541084;
  color: white;
}
